export const reactKeyGenerator = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const formatDateInput = (dateStr) => {
  // used to format <input> fields since the date
  // values are different from what is generated by the server.
  if (dateStr === "") {
    return;
  }
  const dateArr = dateStr.split("-");

  const [year, month, day] = dateArr;

  const date = new Date(`${year}`, `${parseInt(month) - 1}`, `${day}`);
  const formattedDate = `${date.toLocaleString("en-us", {
    month: "long",
  })} ${parseInt(day)}, ${year},`;
  return formattedDate;
};

export const formatDateInputData = (dateStr) => {
  // used to format <input> fields since the date
  // values are different from what is generated by the server.
  if (dateStr === "") {
    return;
  }
  const dateArr = dateStr.split("-");
  const [year, month, day] = dateArr;
  const formattedDate = `${month}/${
    day.indexOf("T") > 0 ? day.substr(0, day.indexOf("T")) : day
  }/${year}`;
  return formattedDate;
};

export const isDateExpired = (dateStr) => {
  if (dateStr === "" || dateStr === undefined) {
    return false;
  }
  const dateArr = dateStr.split("-");
  const [year, month, day] = dateArr;
  const date = new Date(`${year}`, `${parseInt(month) - 1}`, `${day}`);
  if (new Date() > date) {
    return true;
  } else {
    return false;
  }
};

export const formatDateServer = (date) => {
  // if we receive and empty string
  // there is no date to format
  if (date === "") {
    return;
  }
  // separate Timestamp from Date
  const dateSplitTime = date.split("T");
  return formatDateInput(dateSplitTime[0]);
};
