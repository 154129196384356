import { string, arrayOf, oneOf, oneOfType, node } from "prop-types";
import classnames from "classnames";

import "./style.scss";

const Text = ({ type, align, className, children }) => {
  const TEXT_CSS = classnames({
    "type-style-p1": type === "p1",
    "type-style-p2": type === "p2",
    "type-style-p3": type === "p3",
    "type-style-p4": type === "p4",
    "ta-left": align === "left",
    "ta-right": align === "right",
    "ta-center": align === "center",
    [className]: className,
  });
  return <div className={TEXT_CSS}>{children}</div>;
};

Text.defaultProps = {
  type: "p1",
};

Text.propTypes = {
  type: oneOf(["p1", "p2", "p3", "p4"]),
  align: oneOf(["left", "right", "center"]),
  className: string,
  children: oneOfType([arrayOf(node), node]),
};

const Heading = ({ type, align, className, children }) => {
  const HEADING_CSS = classnames({
    "type-style-h1": type === "h1",
    "type-style-h2": type === "h2",
    "type-style-h3": type === "h3",
    "type-style-h4": type === "h4",
    "type-style-h5": type === "h5",
    "ta-left": align === "left",
    "ta-right": align === "right",
    "ta-center": align === "center",
    [className]: className,
  });
  const HEADING_TYPES = {
    h1: <h1 className={HEADING_CSS}>{children}</h1>,
    h2: <h2 className={HEADING_CSS}>{children}</h2>,
    h3: <h3 className={HEADING_CSS}>{children}</h3>,
    h4: <h4 className={HEADING_CSS}>{children}</h4>,
    h5: <h5 className={HEADING_CSS}>{children}</h5>,
  };
  return HEADING_TYPES[type];
};

Heading.defaultProps = {
  type: "h2",
};

Heading.propTypes = {
  type: oneOf(["h1", "h2", "h3", "h4", "h5"]),
  align: oneOf(["left", "right", "center"]),
  className: string,
  children: oneOfType([arrayOf(node), node]),
};

const Label = ({ type, htmlFor, align, className, children }) => {
  const LABEL_CSS = classnames({
    "type-style-l1": type === "l1",
    "type-style-l2": type === "l2",
    "type-style-l3": type === "l3",
    "type-style-l4": type === "l4",
    "ta-left": align === "left",
    "ta-right": align === "right",
    "ta-center": align === "center",
    [className]: className,
  });
  return (
    <label className={LABEL_CSS} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

Label.defaultProps = {
  type: "l1",
};

Label.propTypes = {
  type: oneOf(["l1", "l2", "l3", "l4"]),
  align: oneOf(["left", "right", "center"]),
  className: string,
  children: oneOfType([arrayOf(node), node]),
};

export { Text, Heading, Label };
