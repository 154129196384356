import "./style.scss";
import logo from "./amp-logo.png";
import Nav from "../Nav";

export default function Header() {
  const firstname = window.localStorage.getItem("firstname");
  const lastname = window.localStorage.getItem("lastname");

  return (
    <header className="header">
      <div className="header__logo">
        <img className="header__logo-img" src={logo} alt="amp logo" />
      </div>
      <div className="header__username">
        {firstname} {lastname}
      </div>
      <div className="header__nav">
        <Nav />
      </div>
    </header>
  );
}
