import { func, object, string } from "prop-types";
import axios from "axios";

export const getAuthenticatedUser = async () => {
  const activeUser = localStorage.getItem("uid");
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };
  await axios
    .get(`${process.env.REACT_APP_API_HOST}/api/user/${activeUser}/`, headers)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log(err);
      window.localStorage.clear();
      return { success: "false", message: "Unable to get authenticated User" };
    });
};

export const getData = async (
  currentURL, // The URL we're currently getting
  setCurrentURL, // func: sets the next current URL
  setLoading, // func(bool): lets us know we're still laoding data
  setData // func(array): appends response array to the end of the last set of data
) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
      },
    };
    // get the next set of values
    await axios.get(currentURL, headers).then((res) => {
      const data = res.data;
      // append values to the end of the array
      data.results.forEach((item) => {
        setData((messageMeta) => [...messageMeta, item]);
      });
      // if there is another page to load in pagination
      if (data.next !== null) {
        // set the next url
        setCurrentURL(data.next);
      } else {
        // there are no more pagiation links
        // break out of loop
        setLoading(false);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

getData.propTypes = {
  currentURL: string,
  setCurrentURL: func,
  setLoading: func,
  setData: func,
};

export const deleteRow = async (URL, callback) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };
  try {
    await axios.delete(URL, headers).then((res) => {
      callback(res);
    });
  } catch (error) {
    console.log(error);
    callback(error);
  }
};

deleteRow.propTypes = {
  URL: string,
};

export const put = async (URL, payload, callback) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };
  try {
    await axios.put(URL, payload, headers).then((res) => {
      callback(res);
    });
  } catch (error) {
    callback(error);
  }
};

put.propTypes = {
  URL: string,
  payload: object,
  callback: func,
};

export const post = async (URL, payload, callback) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };
  try {
    await axios.post(URL, payload, headers).then((res) => {
      callback(res);
    });
  } catch (error) {
    callback(error);
  }
};

post.propTypes = {
  URL: string,
  payload: object,
  callback: func,
};

export const get = async (URL, callback) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };
  try {
    await axios.get(URL, headers).then((res) => {
      callback(res);
    });
  } catch (error) {
    callback(error);
  }
};

get.propTypes = {
  URL: string,
  payload: object,
  callback: func,
};
