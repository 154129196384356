import { bool, func, object, string } from "prop-types";
import { Label, Text } from "../../../components/UILib";

export const AddUser = ({
  firstname,
  lastname,
  email,
  setFirstname,
  setLastname,
  setEmail,
  setPermissions,
  authUserIsAdmin,
}) => {
  const toggleUserType = (event) => {
    // remove checked radios
    document.querySelectorAll(".user__select-type>input").forEach((item) => {
      item.checked = false;
    });
    // apply checked to the event target
    event.target.checked = true;
    if (event.target.dataset.usertype === "user") {
      setPermissions({
        is_admin: false,
        is_superuser: false,
      });
    }
    if (event.target.dataset.usertype === "superuser") {
      setPermissions({
        is_admin: false,
        is_superuser: true,
      });
    }
    if (event.target.dataset.usertype === "admin") {
      setPermissions({
        is_admin: true,
        is_superuser: true,
      });
    }
  };

  return (
    <section className="user__addnewuser">
      <div className="user__input-group">
        <Label htmlFor="firstname">First Name:</Label>
        <input
          id="firstname"
          name="firstname"
          type="text"
          className="user__input"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
      </div>

      <div className="user__input-group">
        <Label htmlFor="lastname">Last Name:</Label>
        <input
          id="lastname"
          name="lastname"
          type="text"
          className="user__input"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
      </div>

      <div className="user__input-group">
        <Label htmlFor="email">Email:</Label>
        <input
          id="email"
          name="email"
          type="text"
          className="user__input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="user__enter-details">
        <Label align="right">User Type:</Label>
        <div className="user__radio-wrapper">
          <div className="user__select-type">
            <input
              id="user-type-is-user"
              name="user-type-is-user"
              data-usertype="user"
              onClick={toggleUserType}
              type="radio"
            />
            <Text align="left" htmlFor="user-type-is-user">
              User
            </Text>
          </div>
          <div className="user__select-type">
            <input
              id="user-type-is-super"
              name="user-type-is-super"
              data-usertype="superuser"
              onClick={toggleUserType}
              type="radio"
            />
            <Text align="left" htmlFor="user-type-is-super">
              Super User
            </Text>
          </div>
          {authUserIsAdmin && (
            <div className="user__select-type">
              <input
                id="user-type-is-super"
                name="user-type-is-super"
                data-usertype="admin"
                onClick={toggleUserType}
                type="radio"
              />
              <Text align="left" htmlFor="user-type-is-super">
                Admin
              </Text>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

AddUser.propTypes = {
  firstname: string,
  lastname: string,
  email: string,
  setFirstname: func,
  setLastname: func,
  setEmail: func,
  authUserIsAdmin: bool,
};

export const ConfirmAddUser = ({ firstname, lastname, email, permission }) => {
  const userType = () => {
    if (permission.is_admin) {
      return "Admin";
    }
    if (permission.is_superuser) {
      return "Super User";
    }
    return "User";
  };
  return (
    <section className="user__wrapper">
      <div className="user__labels">
        <Text align="right">First Name:</Text>
        <Text align="right">Last Name:</Text>
        <Text align="right">Email:</Text>
        <Text align="right">User Type:</Text>
      </div>
      <div className="user__edit-details">
        <Text align="left">{firstname}</Text>
        <Text align="left">{lastname}</Text>
        <Text align="left">{email}</Text>
        <Text align="left">{userType()}</Text>
      </div>
    </section>
  );
};

ConfirmAddUser.propTypes = {
  firstname: string,
  lastname: string,
  email: string,
};

export const EditUser = ({
  user, // object: The user we're are editing
  authUserIsAdmin, // bool: wether or not the logged in user is an admin
  setPermissions, // func: Setter method for permissions
}) => {
  const toggleUserType = (event) => {
    // remove checked radios
    document.querySelectorAll(".user__select-type>input").forEach((item) => {
      item.checked = false;
    });
    // apply checked to the event target
    event.target.checked = true;

    if (event.target.dataset.usertype === "user") {
      setPermissions({
        is_admin: false,
        is_superuser: false,
      });
    }
    if (event.target.dataset.usertype === "superuser") {
      setPermissions({
        is_admin: false,
        is_superuser: true,
      });
    }
    if (event.target.dataset.usertype === "admin") {
      setPermissions({
        is_admin: true,
        is_superuser: true,
      });
    }
  };

  return (
    <section className="user__wrapper">
      <div className="user__labels">
        <Text align="right">User:</Text>
        <Text align="right">User Type:</Text>
      </div>
      <div className="user__edit-details">
        <Text aliggn="left">
          {user.first_name} {user.last_name}
        </Text>
        <div className="user__select-type">
          <input
            id="user-type-is-user"
            name="user-type-is-user"
            data-usertype="user"
            onClick={toggleUserType}
            type="radio"
          />
          <Text align="left" htmlFor="user-type-is-user">
            User
          </Text>
        </div>
        <div className="user__select-type">
          <input
            id="user-type-is-super"
            name="user-type-is-super"
            data-usertype="superuser"
            onClick={toggleUserType}
            type="radio"
          />
          <Text align="left" htmlFor="user-type-is-super">
            Super User
          </Text>
        </div>
        {authUserIsAdmin && (
          <div className="user__select-type">
            <input
              id="user-type-is-super"
              name="user-type-is-super"
              data-usertype="admin"
              onClick={toggleUserType}
              type="radio"
            />
            <Text align="left" htmlFor="user-type-is-super">
              Admin
            </Text>
          </div>
        )}
      </div>
    </section>
  );
};

EditUser.propTypes = {
  data: object,
  authUserIsAdmin: bool,
  setPermissions: func,
};

export const EditUserConfirm = ({
  user, // object: the user we're editing
  permissions, // object: The permissios we're passing to the API
}) => {
  let usertype = "";

  if (!permissions.is_superuser && !permissions.is_admin) {
    usertype = "User";
  }

  if (permissions.is_superuser) {
    usertype = "Super User";
  }
  if (permissions.is_admin) {
    usertype = "Admin";
  }

  return (
    <section className="user__wrapper">
      <div className="user__labels">
        <Text align="right">User:</Text>
        <Text aliggn="right">User Type:</Text>
      </div>
      <div className="user__edit-details">
        <Text aliggn="left">
          {user.first_name} {user.last_name}
        </Text>
        <Text align="left" htmlFor="user-type-is-user">
          {usertype}
        </Text>
      </div>
    </section>
  );
};

EditUserConfirm.propTypes = {
  user: object,
  permissions: object,
};

export const DeleteUser = ({ user }) => {
  const userType = () => {
    if (user.is_admin) {
      return "Admin";
    }
    if (user.is_superuser) {
      return "Super User";
    }
    return "User";
  };
  return (
    <section className="user__wrapper">
      <div className="user__labels">
        <Text align="right">User:</Text>
        <Text aliggn="right">User Type:</Text>
      </div>
      <div className="user__delete-details">
        <Text aliggn="left">
          {user.first_name} {user.last_name}
        </Text>
        <Text aliggn="left">{userType()}</Text>
      </div>
    </section>
  );
};

DeleteUser.propTypes = {
  user: object,
};
