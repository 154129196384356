import classnames from "classnames";
import { func, string, oneOfType, arrayOf, node } from "prop-types";
import "./style.scss";
import { useEffect, useState } from "react";

export default function Button({
  id,
  className,
  type,
  text,
  onClick,
  children,
  disabled,
}) {
  const BUTTON_CSS = classnames({
    button: "general",
    [className]: className,
  });
  const [state, setState] = useState({
    value: "",
    submitting:
      disabled === "" || disabled === undefined || disabled === null
        ? false
        : true,
  });

  return (
    <button
      id={id}
      className={BUTTON_CSS}
      type={type}
      onClick={onClick}
      disabled={state.submitting}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  type: "button",
};

Button.propTypes = {
  className: string,
  type: string,
  text: string,
  onClick: func,
  children: oneOfType([arrayOf(node), node]),
};
