import { Text, Heading, Label } from "../UILib";

export default function Type({ setTemplateType, setTemplateTypeSelected }) {
  const handleClick = (event) => {
    let msgTemplateTypes = document.querySelectorAll('[data-type="template"]');
    msgTemplateTypes.forEach((element) => {
      element.checked = false;
    });
    setTemplateType(event.target.name);
    setTemplateTypeSelected(true);
    event.target.checked = true;
  };

  return (
    <section className="type">
      <div className="type__content">
        <Heading type="h3" align="left">
          Select Message Type:
        </Heading>
        <div className="type__inputs">
          <input
            id="multiple-plan"
            name="multiple-plan"
            data-type="template"
            data-newmessageradio={true}
            type="radio"
            onClick={handleClick}
          />
          <Label type="l3" align="left" htmlFor="multiple-plan">
            Multiple Plan
          </Label>
          <input
            id="competitive"
            name="competitive"
            data-type="template"
            data-newmessageradio={true}
            type="radio"
            onClick={handleClick}
          />
          <Label type="l3" align="left" htmlFor="competitive">
            Competitive
          </Label>
          <input
            id="new-win"
            name="new-win"
            data-type="template"
            data-newmessageradio={true}
            type="radio"
            onClick={handleClick}
          />
          <Label type="l3" align="left" htmlFor="new-win">
            New Win
          </Label>
          <input
            id="continuing-coverage"
            name="continuing-coverage"
            data-type="template"
            data-newmessageradio={true}
            type="radio"
            onClick={handleClick}
          />
          <Label type="l3" align="left" htmlFor="continuing-coverage">
            Continuing Coverage
          </Label>
        </div>

        <Text type="p3" align="left" className="type__p3">
          Make selections from the fields below. Once all the mandatory fields
          which are indicated by an asterisk (*) are complete, use the
          "Preview"button to review the message. Use the "Reset" button to clear
          all the fields.
        </Text>
      </div>
    </section>
  );
}
