import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Label } from "../../../../components/UILib";
import { showDropdownOptions } from "./libs/filterfuncs";
import Labeledoader from "../../../../components/LabeledLoader";

export function UserFilter({ id, name, data, loggedInUser, setFilterByUsers }) {
  const userFullName = `${window.localStorage.getItem(
    "firstname"
  )} ${window.localStorage.getItem("lastname")}`;
  // Filtering for the authenticated user by default
  // ad the <Dashboard> Component. Setting the initial state for
  // currentUser to be the authenticated users name.
  const [currentUser, setCurrentUser] = useState(loggedInUser);

  if (currentUser === "null null" && loggedInUser !== "null null") {
    setCurrentUser(loggedInUser);
  }
  // Selects and options and changes
  // the value of the current selection
  const handleSelection = (event) => {
    const target = event.target.dataset.target;
    document.querySelector(target).classList.add("d-none");
    setCurrentUser(event.target.dataset.value);
    if (event.target.dataset.value === "All") {
      setFilterByUsers("");
      return;
    }
    setFilterByUsers(event.target.dataset.id);
  };

  const hanldeMouseLeave = (event) => {
    document.querySelector("#user-filter").classList.add("d-none");
  };

  return (
    <section className="filteroptions">
      <Label type="l1">
        <strong>User:</strong>
      </Label>
      <div className="filteroptions__wrapper">
        {currentUser === "null null" ? (
          <Labeledoader label="Loading users..." />
        ) : (
          <div
            className="filteroptions__current-selection filteroptions__item"
            data-target={`#${id}`}
            onClick={showDropdownOptions}
          >
            {parse(currentUser)} <span>^</span>
          </div>
        )}

        <div
          id={id}
          className="filteroptions__list d-none"
          onMouseLeave={hanldeMouseLeave}
        >
          <div
            className="filteroptions__item"
            data-target={`#${id}`}
            data-value="All"
            onClick={handleSelection}
          >
            All
          </div>

          {data.map((item) => {
            return (
              <div
                key={item.email}
                className="filteroptions__item"
                data-id={item.id}
                data-target={`#${id}`}
                data-value={`${item.first_name} ${item.last_name}`}
                data-filter="user"
                onClick={handleSelection}
              >
                {parse(item.email)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export function DeploymentFilter({ id, name, data, setFilterByDeployment }) {
  const [currentDeployment, setCurrentDeployment] = useState("All");

  // Selects and options and changes
  // the value of the current selection
  const handleSelection = (event) => {
    const target = event.target.dataset.target;
    document.querySelector(target).classList.add("d-none");
    setCurrentDeployment(event.target.dataset.value);
    if (event.target.dataset.value === "All") {
      setFilterByDeployment("");
      return;
    }
    setFilterByDeployment(event.target.dataset.value);
  };

  const hanldeMouseLeave = (event) => {
    document.querySelector("#deployments-filter").classList.add("d-none");
  };

  return (
    <section className="filteroptions">
      <Label type="l1">
        <strong>Deployment:</strong>
      </Label>
      <div className="filteroptions__wrapper">
        <div
          className="filteroptions__current-selection filteroptions__item"
          data-target={`#${id}`}
          onClick={showDropdownOptions}
        >
          {parse(currentDeployment)} <span>^</span>
        </div>
        <div
          id={id}
          className="filteroptions__list d-none"
          onMouseLeave={hanldeMouseLeave}
        >
          <div
            className="filteroptions__item"
            data-target={`#${id}`}
            data-value="All"
            onClick={handleSelection}
          >
            All
          </div>

          {data.map((item) => {
            return (
              <div
                key={item.name}
                className="filteroptions__item"
                data-target={`#${id}`}
                data-filter="deployment"
                data-value={item.name}
                onClick={handleSelection}
              >
                {parse(item.name)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export function ProductFilter({
  id,
  name,
  data,
  setFilterByProduct, // func: onChange -> apply the filter to messages at the <Dashboard> component
  selectedProduct, // string: The product that was selected used to change Brand dropdown options
  setSelectedProduct, // func:
}) {
  const handleSelection = (event) => {
    // onClick: select an option from the custom dropdown

    // hide the custom dropdown menu
    document.querySelector(event.target.dataset.target).classList.add("d-none");
    // and set the value of the current selection
    // this is passed to the brand filter
    // use this setter to manage the filter for brands
    setSelectedProduct(event.target.dataset.value);

    setFilterByProduct(event.target.dataset.value);
    if (event.target.dataset.value === "All") {
      setFilterByProduct("");

      // Disable Brand dropdown When all products are selcted
      document
        .querySelector("#product-brand-section")
        .classList.add("filteroptions--noevents");

      return;
    }

    // Enable Brand dropdown when a product is selected
    document
      .querySelector("#product-brand-section")
      .classList.remove("filteroptions--noevents");
  };

  const hanldeMouseLeave = (event) => {
    document.querySelector("#product-list").classList.add("d-none");
  };

  return (
    <section className="filteroptions">
      <Label type="l1">
        <strong>Product:</strong>
      </Label>
      <div className="filteroptions__wrapper">
        <div
          className="filteroptions__current-selection filteroptions__item"
          data-target={`#${id}`}
          onClick={showDropdownOptions}
        >
          {parse(selectedProduct)} <span>^</span>
        </div>
        <div
          id={id}
          className="filteroptions__list d-none"
          onMouseLeave={hanldeMouseLeave}
        >
          <div
            className="filteroptions__item"
            data-target={`#${id}`}
            data-value="All"
            onClick={handleSelection}
          >
            All
          </div>

          {data.map((item) => {
            return (
              <div
                key={item.product_name}
                className="filteroptions__item"
                data-target={`#${id}`}
                data-value={item.product_name}
                data-filter="product"
                onClick={handleSelection}
              >
                {parse(item.product_name)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export function BrandFilter({
  id,
  name,
  data,
  setFilterByBrand,
  selectedProduct,
}) {
  const [currentBrand, setCurrentBrand] = useState("All");

  useEffect(() => {
    // when the selectedProduct changes, set the currentBrand to "All"
    setCurrentBrand("All");
  }, [selectedProduct]);

  const handleSelection = (event) => {
    // Selects and options and changes
    // the value of the current selection

    // hide the custom dropdown menu
    document.querySelector(event.target.dataset.target).classList.add("d-none");

    setCurrentBrand(event.target.dataset.value);

    if (event.target.dataset.value === "All") {
      setFilterByBrand("");
      return;
    }
    setFilterByBrand(event.target.dataset.value);
  };

  const hanldeMouseLeave = (event) => {
    document.querySelector("#brand-list").classList.add("d-none");
  };

  return (
    <section
      id="product-brand-section"
      className="filteroptions filteroptions--noevents"
    >
      <Label type="l1">
        <strong>Brand:</strong>
      </Label>
      <div className="filteroptions__wrapper">
        <div
          className="filteroptions__current-selection filteroptions__item"
          data-target={`#${id}`}
          onClick={showDropdownOptions}
        >
          {parse(currentBrand)} <span>^</span>
        </div>
        <div
          id={id}
          className="filteroptions__list d-none"
          onMouseLeave={hanldeMouseLeave}
        >
          <div
            className="filteroptions__item"
            data-target={`#${id}`}
            data-value="All"
            onClick={handleSelection}
          >
            All
          </div>

          {data.map((item) => {
            if (item.product_name !== selectedProduct) {
              return;
            }
            return (
              <div
                key={item.value}
                className="filteroptions__item"
                data-target={`#${id}`}
                data-value={item.value}
                onClick={handleSelection}
              >
                {parse(item.value)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
