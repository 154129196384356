import React, { useState, useEffect, Suspense, lazy } from "react";
import { func, bool, string } from "prop-types";
import Labeledoader from "../LabeledLoader";

import "./style.scss";

import Button from "../Button";
import { MessageSelectOptionField, ProductOptionField } from "./InputTemplates";
import { getData } from "../../utils/requests";

// message templates
const Compatative = lazy(() =>
  import("../../pages/Template/messageTypes/Competative")
);
const ContinuingCoverage = lazy(() =>
  import("../../pages/Template/messageTypes/ContinuingCoverage")
);
const MultiPlan = lazy(() =>
  import("../../pages/Template/messageTypes/MultiPlan")
);
const NewWin = lazy(() => import("../../pages/Template/messageTypes/NewWin"));

// todo: Add a template type for generating different types of messages
export default function MessageTemplateInputs({
  visible,
  templateType,
  clearInputs, // clears all New message inputs
  productSelected, // boolean: if ture show all other message options after product
  constructingPreview, // boolean: if true re-builds the preview message
  setConstructingPreview,
  handlePreviewChangeEvent, // triggers a preview re-build
  setProductSelected, // toggles visibility of <select> tags
  setMessagePreview,
  setModalClassName,
  setModalHeaderText,
  setModalBodyText,
  setModalBodyTextTop,
  setModalButtonOneText,
  setModalButtonOneType,
  setModalButtonOneClassName,
  setModalButtonOneOnClick,
  setModalButtonTwoText,
  setModalButtonTwoType,
  setModalButtonTwoClassName,
  setModalButtonTwoOnClick,
  setModalChildren,
  selectedProduct,
  setSelectedProduct,
  handleStaticFieldChangeEvent,
  handleErrorEvent,
}) {
  const [messageMeta, setMessageMeta] = useState([]);
  const [loadingMessageMeta, setLoadingMessageMeta] = useState(true);
  const [productMetaUrl, setProductMetaUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/product-meta/`
  );
  let disabled = false;
  let fieldDisabled='';
  // used to filter input options based on
  // the product that is selected - @mindy : removed this
  // const [selectedProduct, setSelectedProduct] = useState("");

 /*
 ///enable competitive for Cyltezo product 12-05-23
  if(selectedProduct === 'Cyltezo' && templateType === "competitive"  ){
    if(disabled ==false){

      disabled = true;
      handleErrorEvent('A message Competitive template for Cyltezo brand has been Disabled')
    }

  }*/

  if(disabled){
    fieldDisabled ='disabled';
  }

  useEffect(() => {
    getData(
      productMetaUrl,
      setProductMetaUrl,
      setLoadingMessageMeta,
      setMessageMeta
    );
  }, [productMetaUrl]);

  const handleModalCancel = () => {
    setModalClassName("d-none");
    document
      .querySelector("#create-new-message")
      .classList.remove("msg--disable-scroll");
  };

  const handlePreview = () => {
    setModalClassName("");
    setModalHeaderText("Message Preview");
    setModalBodyTextTop("");
    setModalBodyText("");
    setModalButtonOneText("");
    setModalButtonOneClassName("d-none");
    setModalButtonOneOnClick();
    setModalButtonTwoText("Close");
    setModalButtonTwoOnClick(() => handleModalCancel);
    setModalChildren("previewMessage");

    // scrollto top of new message and disable overflow
    document.querySelector("#create-new-message").scrollTo(0, 0);
    document
      .querySelector("#create-new-message")
      .classList.add("msg--disable-scroll");
  };

  const uniqueProducts = [
    ...new Map(
      messageMeta
        .filter((a) => a.type === "product")
        .map((item) => [item["product_name"], item])
    ).values(),
  ];

  return (
    <section className={visible}>
      {loadingMessageMeta ? (
        <Labeledoader label="Loading message options..." />
      ) : (
        <>
          <ProductOptionField
            id="parent-product"
            labelText="Product"
            djfield="parent-product"
            data={uniqueProducts}
            fieldRequired="*"
            setSelectedProduct={setSelectedProduct}
            setProductSelected={setProductSelected}
          />
          {productSelected ? (
            <Suspense fallback={<div>loading...</div>}>
              <MessageSelectOptionField
                id="product"
                labelText="Brand"
                djfield="product"
                data={messageMeta.filter((item) => item.type === "product")}
                setConstructingPreview={setConstructingPreview}
                handlePreviewChangeEvent={handlePreviewChangeEvent}
                fieldRequired="*"
                selectedProduct={selectedProduct}
                disabled={fieldDisabled}
              />
              {templateType === "multiple-plan" && (
                <MultiPlan
                  messageMeta={messageMeta}
                  handleStaticFieldChangeEvent ={handleStaticFieldChangeEvent}
                  setConstructingPreview={setConstructingPreview}
                  handlePreviewChangeEvent={handlePreviewChangeEvent}
                  selectedProduct={selectedProduct}
                  handleErrorEvent={handleErrorEvent}
                />
              )}
              {templateType === "competitive"  && (
                <Compatative
                  messageMeta={messageMeta}
                  handleStaticFieldChangeEvent ={handleStaticFieldChangeEvent}
                  setConstructingPreview={setConstructingPreview}
                  handlePreviewChangeEvent={handlePreviewChangeEvent}
                  selectedProduct={selectedProduct}
                  handleErrorEvent={handleErrorEvent}
                  disabled={disabled}
                />
              )}
              {templateType === "new-win" && (
                <NewWin
                  messageMeta={messageMeta}
                  handleStaticFieldChangeEvent ={handleStaticFieldChangeEvent}
                  setConstructingPreview={setConstructingPreview}
                  handlePreviewChangeEvent={handlePreviewChangeEvent}
                  selectedProduct={selectedProduct}
                  handleErrorEvent={handleErrorEvent}
                />
              )}
              {templateType === "continuing-coverage" && (
                <ContinuingCoverage
                  messageMeta={messageMeta}
                  handleStaticFieldChangeEvent ={handleStaticFieldChangeEvent}
                  setConstructingPreview={setConstructingPreview}
                  handlePreviewChangeEvent={handlePreviewChangeEvent}
                  selectedProduct={selectedProduct}
                  handleErrorEvent={handleErrorEvent}
                />
              )}
            </Suspense>
          ) : (
            <div>Select a product</div>
          )}
        </>
      )}
      <div className="message-options__button-group">
        <Button
          id="preview-new-message"
          type="button"
          text="Preview"
          className="message-options__preview-btn"
          onClick={handlePreview}
        />
        <Button
          id="cancel-new-message"
          type="button"
          text="Reset"
          className="button__stripped"
          onClick={clearInputs}
        />
      </div>
    </section>
  );
}

MessageTemplateInputs.propTypes = {
  templateType: string,
  clearInputs: func,
  productSelected: bool,
  handleProductSelectEvent: func,
  setConstructingPreview: func,
  handlePreviewChangeEvent: func,
  handleStaticFieldChangeEvent:func,
  handleErrorEvent:func,
};
