import { oneOfType, arrayOf, string, node } from "prop-types";
import parse from "html-react-parser";
import "./style.scss";

import Button from "../Button";

export default function Modal({
  headerText,
  bodyTextTop,
  bodyText,
  className,
  buttonOne,
  buttonTwo,
  children,
}) {
  const [
    buttonOneText,
    buttonOneType,
    buttonOneClassName,
    buttonOneHandleClick,
  ] = buttonOne;
  const [
    buttonTwoText,
    buttonTwoType,
    buttonTwoClassName,
    buttonTwoHanldeClick,
  ] = buttonTwo;
  return (
    <section id="modal" className={`modal ${className}`}>
      <div className="modal__content">
        <div className="modal__header">
          {headerText && <h3 className="modal__h3">{headerText}</h3>}
        </div>
        <div className="modal__body">
          {bodyTextTop && <p className="modal__p">{parse(bodyTextTop)}</p>}

          {children}

          {bodyText && <p className="modal__p">{parse(bodyText)}</p>}

          <div className="modal__buttons">
            <Button
              text={buttonOneText}
              type={buttonOneType}
              className={buttonOneClassName}
              onClick={buttonOneHandleClick}
            />
            <Button
              text={buttonTwoText}
              type={buttonTwoType}
              className={buttonTwoClassName}
              onClick={buttonTwoHanldeClick}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

Modal.propTypes = {
  headerText: string,
  bodyText: string,
  className: string,
  // buttonOne: oneOfType([string, func]),
  // buttonTow: oneOfType([string, func]),
  children: oneOfType([arrayOf(node), node]),
};
