export const checkInputsAndSelects = () => {
  let fieldMissed = false;
  // Check <input> & <Select>
  document.querySelectorAll("[required]").forEach((item) => {
    // add error state if empty
    // set the flag to true
    if (item.value === "") {
      item.classList.add("input--error");
      fieldMissed = true;
    } else {
      item.classList.remove("input--error");
    }
  });
  return fieldMissed;
};

export const checkTemplateRadios = () => {
  // Check <input type="radio" data-type="template/>
  let radioChecked = false;
  // make sure at least one radio is checked
  document.querySelectorAll("[data-type=template").forEach((item) => {
    item.classList.remove("input--error");
    if (item.checked) {
      radioChecked = true;
    }
  });
  // if no radio is checked
  // add an error state to all
  if (radioChecked) {
    document.querySelector(".type__inputs").classList.remove("input--error");
  } else {
    document.querySelector(".type__inputs").classList.add("input--error");
  }
  return radioChecked;
};

export const checkDeploymentRadios = () => {
  // Check <input type="radio" data-type="deployment" />
  let radioChecked = false;
  // make sure at least one radio is checked
  document.querySelectorAll("[data-type=deployment").forEach((item) => {
    item.classList.remove("input--error");
    if (item.checked) {
      radioChecked = true;
    }
  });
  // if no radio is checked
  // add an error state to all
  if (radioChecked) {
    document
      .querySelector(".deployment__options")
      .classList.remove("input--error");
  } else {
    document
      .querySelector(".deployment__options")
      .classList.add("input--error");
  }
  return radioChecked;
};

export const checkPermissionRadios = () => {
  // Check <input type="radio" data-type="permission" />
  let radioChecked = false;
  // make sure at least one radio is checked
  document.querySelectorAll("[data-type=permission").forEach((item) => {
    item.classList.remove("input--error");
    if (item.checked) {
      radioChecked = true;
    }
  });
  // if no radio is checked
  // add an error state to all
  if (radioChecked) {
    document
      .querySelector(".permission__content")
      .classList.remove("input--error");
  } else {
    document
      .querySelector(".permission__content")
      .classList.add("input--error");
  }
  return radioChecked;
};

export const checkRequiredInputs = () => {
  let fieldsMissed = false;

  let inputsMissed = checkInputsAndSelects();
  if (inputsMissed) {
    fieldsMissed = true;
  }

  let templateRadioChecked = checkTemplateRadios();
  if (!templateRadioChecked) {
    fieldsMissed = true;
  }

  let deploymentRadioChecked = checkDeploymentRadios();
  if (!deploymentRadioChecked) {
    fieldsMissed = true;
  }

  let permissionRadioChecked = checkPermissionRadios();
  if (!permissionRadioChecked) {
    fieldsMissed = true;
  }

  return fieldsMissed;
};

export const checkSelectedDeploymentOptions = () => {
  // if State, District or City/CBSA deployment
  // is selected; make sure options are selected as well
  let optionsMissed = true;
  let activeRadio = "";

  // find the active Deployment type
  document.querySelectorAll("input[data-type=deployment]").forEach((elm) => {
    if (elm.checked) {
      activeRadio = elm.name;
    }
  });

  // we can ignore National
  if (activeRadio === "National") {
    optionsMissed = false;
    return optionsMissed;
  }

  document.querySelectorAll(".deployment-selections").forEach((elm) => {
    if (elm.innerHTML !== "") {
      optionsMissed = false;
      return optionsMissed;
    }
  });
  return optionsMissed;
};

export const checkDateDeltas = () => {
  let response = { error: false, message: "OK" };

  const goLiveDateElm = document.querySelector("#go-live-date");
  const expirationDateElm = document.querySelector("#expiration-date");

  let goLiveDate = document.querySelector("#go-live-date").value;
  let expirationDate = document.querySelector("#expiration-date").value;

  // if everything is empty, we don't care
  if (goLiveDate === "" && expirationDate === "") {
    goLiveDateElm.classList.remove("input--error");
    expirationDateElm.classList.remove("input--error");
    return response;
  }

  // one is empty and the other is not
  // show error
  if (
    (goLiveDate === "" && expirationDate !== "") ||
    (goLiveDate !== "" && expirationDate === "")
  ) {
    goLiveDateElm.classList.add("input--error");
    expirationDateElm.classList.add("input--error");

    response = {
      error: true,
      message:
        "Enter a Go-Live date and expriation date, or clear both fields.",
    };
    return response;
  }

  goLiveDate = new Date(goLiveDate);
  expirationDate = new Date(expirationDate);

  const timedelta = expirationDate - goLiveDate;

  if (timedelta < 0) {
    goLiveDateElm.classList.add("input--error");
    expirationDateElm.classList.add("input--error");
    response = {
      error: true,
      message: "The expriation date cannot come before the Go-Live date.",
    };
  } else {
    // remove error states
    goLiveDateElm.classList.remove("input--error");
    expirationDateElm.classList.remove("input--error");
  }

  return response;
};
