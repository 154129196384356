import React from "react";
import { func } from "prop-types";
import "./style.scss";
import BiFooter from "../BI/Footer";

export default function Footer({ showNewMessageEvent }) {
  return (
    <footer className="footer">
      <BiFooter />
      <section>
        <button className="dashboard__add-new" onClick={showNewMessageEvent}>
          <span className="dashboard__add-new-icon">
            <span></span>
            <span></span>
          </span>
          <p>Add new message</p>
        </button>
      </section>
    </footer>
  );
}

Footer.propTypes = {
  showNewMessageEvent: func,
};

export function RosterFooter({ showNewUserModalOnClick }) {
  return (
    <footer className="footer">
      <BiFooter />
      <section>
        <button
          className="dashboard__add-new"
          onClick={showNewUserModalOnClick}
        >
          <span className="dashboard__add-new-icon">
            <span></span>
            <span></span>
          </span>
          <p>Add new User</p>
        </button>
      </section>
    </footer>
  );
}

RosterFooter.propTypes = {
  showNewUserModalEvent: func,
};
