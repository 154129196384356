import { array, bool, func, string } from "prop-types";
import { reactKeyGenerator } from "../../../utils";
import { useState, useEffect } from "react";
import Select from "react-select";

export default function Filter({
  name, // string: the name of the filter type
  data, // array: the data for the filter
  setBrandSelected, // func tells if something was selectged
  setSelectedBrand,
  onBrandChange,
}) {
  const [product, setProduct] = useState();
  const [brand, setBrand] = useState();
  const [brandList, setBrandList] = useState();

  const productStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: state.isFocused ? "0 0 0 0" : 0,
      borderColor: state.isFocused ? "#333" : "#333",
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: 0,
      hyphens: "auto",
      marginTop: 0,
      textAlign: "left",
      wordWrap: "break-word",
      background: state.isSelected ? "#eee" : "#fff",
    }),

    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };
  const productBrand = data.map(function (item) {
    return {
      product: item.product_name,
      brand: data
        .filter(function (o) {
          return o.product_name === item.product_name;
        })
        .reduce(function (r, a) {
          if (r.product_name === a.product_name) {
            r = { ...r, a };
          } else {
            r = a || {};
          }
          return r;
        }, Object.create(null)),
    };
  });

  // select drop down meta
  const productBrandList = [
    ...new Map(productBrand.map((item) => [item["product"], item])).values(),
  ];

  const handleBrandChange = (obj) => {
    setBrand({ value: obj.value });
    setSelectedBrand(obj.id);
    setBrandSelected(true);
    onBrandChange();
  };
  const handleProductChange = (obj) => {
    setBrandList(data.filter((item) => item.product_name === obj.product));
    setBrand(null);
  };

  return (
    <>
      <div className="filters__filter">
        <div className="filters__pair">
          <label htmlFor={`${name}-filter`} className="filters__label">
            <strong>{name}:</strong>
          </label>
          <section className="filters__group">
            <Select
              key={`product_unique_select_key__${reactKeyGenerator}`}
              closeMenuOnSelect={true}
              placeholder="Select Product"
              isMulti={false}
              value={product}
              options={productBrandList}
              onChange={handleProductChange}
              getOptionLabel={(x) => x.product}
              getOptionValue={(x) => x.product}
              styles={productStyles}
            />

            <label htmlFor={`brand-filter`} className="filters__label">
              <strong>Brand:</strong>
            </label>
            <Select
              key={`brand_unique_select_key__${reactKeyGenerator}`}
              placeholder="Select Brand"
              value={brand}
              options={brandList}
              onChange={handleBrandChange}
              getOptionLabel={(x) => x.value}
              getOptionValue={(x) => x.id}
              styles={productStyles}
              formatOptionLabel={function (data) {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.value }} />
                );
              }}
            />
          </section>
        </div>
      </div>
    </>
  );
}

Filter.propTypes = {
  name: string,
  data: array,
  onChange: func,
  setOptionSelected: bool,
};
