import React, { useEffect } from "react";
import { bool, func, string } from "prop-types";
// https://www.npmjs.com/package/html-react-parser
// using this parser because there are some Products that come with <sup>&reg;</sup>
import parse from "html-react-parser";
import { Heading, Text } from "../UILib";
import { formatDateInput } from "../../utils";

export default function Preview({
  messagePreview,
  setMessagePreview,
  constructingPreview,
  setConstructingPreview,
}) {
  useEffect(() => {
    const buildMessagePreview = () => {
      let buildingUL = false;
      let multiplanIschecked = document.querySelector("#multiple-plan").checked;
      let str = "";
      //let cyltezp_footnote = '<br>*Interchangeability of CYLTEZO has been demonstrated for the condition(s) of use, strength(s), dosage form(s), and route(s) of administration described in its Full Prescribing Information.'
      let cyltezp_footnote ='<br>*The FDA first approved CYLTEZO as an interchangeable biosimilar (50 mg/mL) to Humira on October 15, 2021. CYLTEZO (100 mg/mL) has not yet been designated as interchangeable. For more information on interchangeability for CYLTEZO, please refer to the Purple Book: https://purplebooksearch.fda.gov/'


      let footnote ='';
      let addFoonote = false;
      // revisit converting strings rballs
      // https://gist.github.com/FranciscoG/0c19eb81869fd2af0100eb7af8b527ab
      // String.fromCharCode(174)
      if (constructingPreview) {
        try {
          // get all message input options by data-type
          const msgOptions = document.querySelectorAll(
            "[data-type=message-options]"
          );
          // concat all items fot build message
          msgOptions.forEach((elm) => {
            // we're dealing with <option> and <input>
            // so we need to check each one

            let tagName = elm.tagName.toLowerCase();

            // if <input>
            if (tagName === "input") {
              // if the input.type is date
              // add the date to the front of the string
              if (elm.id === "effective-date") {
                // if the date is empty clear out
                // the old date and the Effective copy
                if (elm.value === "") {
                  str = `${str}`;
                } else {
                  // instanciate Date Obj and format the date string so
                  // the date shows up correctly in the preview.
                  const formattedDate = formatDateInput(elm.value);
                  str = `Effective ${formattedDate} ${str}`;
                }
                return;
              }

              if (elm.name === "autotext") {
                str = `${str}${elm.value} `;
                return;
              }

              // only apply <ul> to the multiple-plan type
              if (multiplanIschecked) {
                // if <input/> is a plan
                if (
                  ["plan_1", "plan_2", "plan_3", "plan_4"].indexOf(elm.name)
                ) {
                  // only append if not empty
                  // start building a <ul> tag
                  // and place <li> tags within
                  if (!buildingUL) {
                    if (elm.value !== "") {
                      buildingUL = true;
                      str = `${str} <div class="preview__plan-list"><ul><li>${elm.value}</li>`;
                      return;
                    }
                  }

                  // if we're already building a <ul>,
                  // append the next <li>
                  // only append if not empty
                  if (buildingUL) {
                    if (elm.value !== "") {
                      str = `${str}<li>${elm.value}</li>`;
                      return;
                    }
                  }
                }
              }

              // break out of <UL> for other inputs
              // no longer building a <ul>
              // close the tag and continue
              if (buildingUL) {
                buildingUL = false;
                str = `${str}</ul></div>`;
              }
              // used as default for all other <input>s
              str = `${str}${elm.value} `;
            }

            // break out of UL if we're not in an <Input>
            // no longer building a <ul>
            // close the tag and continue
            if (buildingUL) {
              buildingUL = false;
              str = `${str}</ul></div>`;
            }

            // if <option>
            if (tagName === "option") {
              if (elm.selected) {
                str = `${str}${elm.value} `;
                if(elm.dataset.product === 'Cyltezo' && elm.dataset.djangofield ==='optional'){
                  addFoonote =true;
                }

                return;
              }
            }
          });

          // Add a period at the end of all plans accept multiple-plan
          if (!multiplanIschecked) {
            str = `${str.trim()}.`;
          }

          if(addFoonote){
            str = `${str} <br> ${cyltezp_footnote}`;
          }

          setMessagePreview(str);
          setConstructingPreview(false);
        } catch (error) {
          setConstructingPreview(false);
          console.log(error);
        }
      }
    };
    buildMessagePreview();
  }, [constructingPreview, setConstructingPreview, setMessagePreview]);

  return (
    <section className="preview">
      <div className="preview__wrapper">
        <div className="preview__content">
          <Heading type="h3" align="center" className="preview__h3">
            Message Preview
          </Heading>

          <div className="preview__message">
            <Text type="p1" align="left">
              {
                // parse the message preview as html
                // to fix <sup>&reg;</sup> shwing up in plane text
                parse(messagePreview)
              }
            </Text>
          </div>
        </div>
      </div>
      <div className="preview__disclaimer">
        <Text type="p4" align="center">
          Disclaimer - This is a preview only. The message will be formatted by
          the application upon upload.
        </Text>
      </div>
    </section>
  );
}

Preview.propTypes = {
  messagePreview: string,
  setMessagePreview: func,
  constructingPreview: bool,
  setConstructingPreview: func,
};
