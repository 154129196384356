import { array, bool, func, string } from "prop-types";
import { reactKeyGenerator } from "../../../utils";
import { useState, useEffect } from "react";
import Select from "react-select";

export default function Filter({
  name, // string: the name of the filter type
  data, // array: the data for the filter
  setBrandSelected, // func tells if something was selectged
  setSelectedBrand,
  onBrandChange,
}) {
  const [brandList, setBrandList] = useState();
  const params = new URLSearchParams(window.location.search);
  const brandId = params.get("id");

  setBrandSelected(true);
  setSelectedBrand(brandId);

  const productStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: state.isFocused ? "0 0 0 0" : 0,
      borderColor: state.isFocused ? "#333" : "#333",
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: 0,
      hyphens: "auto",
      marginTop: 0,
      textAlign: "left",
      wordWrap: "break-word",
      background: state.isSelected ? "#eee" : "#fff",
    }),

    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };

  const productBrand = data
    .filter((b) => parseInt(b.id) === parseInt(brandId))
    .map(function (item) {
      return {
        product: item.product_name,
        brand: data
          .filter(function (o) {
            return o.product_name === item.product_name;
          })
          .reduce(function (r, a) {
            if (r.product_name === a.product_name) {
              r = { ...r, a };
            } else {
              r = a || {};
            }
            return r;
          }, Object.create(null)),
      };
    });

  // select drop down meta
  const productBrandList = [
    ...new Map(productBrand.map((item) => [item["product"], item])).values(),
  ];

  const selectedBrand = data.filter(
    (item) => parseInt(item.id) === parseInt(brandId)
  );

  return (
    <>
      <div className="filters__filter">
        <div className="filters__pair">
          <label htmlFor={`${name}-filter`} className="filters__label">
            <strong>{name}:</strong>
          </label>
          <section className="filters__group">
            <Select
              key={`product_unique_select_key__${reactKeyGenerator}`}
              closeMenuOnSelect={true}
              isMulti={false}
              isDisabled={true}
              value={productBrandList[0]}
              options={productBrandList}
              getOptionLabel={(x) => x.product}
              getOptionValue={(x) => x.product}
              styles={productStyles}
            />

            <label htmlFor={`brand-filter`} className="filters__label">
              <strong>Brand:</strong>
            </label>
            <Select
              key={`brand_unique_select_key__${reactKeyGenerator}`}
              value={selectedBrand[0]}
              isDisabled={true}
              options={selectedBrand[0]}
              getOptionLabel={(x) => x.value}
              getOptionValue={(x) => x.value}
              styles={productStyles}
              defaultValue={selectedBrand.brand}
              formatOptionLabel={function (data) {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.value }} />
                );
              }}
            />
          </section>
        </div>
      </div>
    </>
  );
}

Filter.propTypes = {
  name: string,
  data: array,
  onChange: func,
  setOptionSelected: bool,
};
