import { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";

export default function Template({ children }) {
  const activeUser = localStorage.getItem("uid");
  const [isLoggedIn, setIsLoggedIn] = useState();

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };

  useEffect(() => {
    const getUserData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_HOST}/api/user/${activeUser}/`,
          headers
        )
        .then((res) => {
          const data = res.data;
          window.localStorage.setItem("firstname", data.first_name);
          window.localStorage.setItem("lastname", data.last_name);
          window.localStorage.setItem("email", data.email);
          window.localStorage.setItem("isAdmin", data.is_admin);
          window.localStorage.setItem("isSuperUser", data.is_superuser);
          window.localStorage.setItem("roster_upload", data.roster_upload);
          window.localStorage.setItem("supervisor", "");
        })
        .catch((err) => {
          console.log(err);
          setIsLoggedIn(false);
          window.localStorage.clear();
          window.open("/login", "_self");
        });
    };

    const loginExpired = () => {
      const lastLogin = new Date(localStorage.getItem("lastLogin"));
      const now = new Date();

      // one day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calc difference between the two dates in milliseconds
      const diffInTime = now.getTime() - lastLogin.getTime();

      // Calc no. of days between dates
      const diffInDays = Math.round(diffInTime / oneDay);

      // expire after 5 days
      if (diffInDays > 5) {
        return true;
      }
      return false;
    };

    const loggedIn = () => {
      if (activeUser === null) {
        window.localStorage.clear();
        window.open("/login", "_self");
        return false;
      }

      if (loginExpired()) {
        // if login is expired,
        // clear local storage and send to login page
        window.localStorage.clear();
        window.open("/login", "_self");
        return false; // We should not hit this return, but keeping as failsafe
      }

      getUserData();
      return true;
    };
    setIsLoggedIn(loggedIn);
  }, [activeUser]);

  return (
    <>
      {isLoggedIn && (
        <>
          <Header />
          <main>{children}</main>
        </>
      )}
    </>
  );
}
