import "./style.scss";
import React, { useState, useEffect } from "react";
import { array, bool } from "prop-types";
import Filter from "./Filter.jsx";
import { getData } from "../../../../utils/requests";
import Labeledoader from "../../../../components/LabeledLoader";
// components
import {
  DeploymentFilter,
  ProductFilter,
  BrandFilter,
  UserFilter,
} from "./ProductMetaFilter";
// libs
import { filterMessages } from "./libs/filterfuncs";

export default function Filters({
  states,
  loadingStates,
  users,
  loadingUsers, // bool:
  loadingMessages, // bool: used to filter messages
}) {
  const authUserId = window.localStorage.getItem("uid");
  // Filters
  const [filterByUsers, setFilterByUsers] = useState(authUserId);
  const [filterByDeployment, setFilterByDeployment] = useState("");
  const [filterByProduct, setFilterByProduct] = useState("");
  const [filterByBrand, setFilterByBrand] = useState("");

  useEffect(() => {
    filterMessages(
      filterByProduct,
      filterByBrand,
      filterByDeployment,
      filterByUsers
    );
  }, [
    filterByUsers,
    filterByDeployment,
    filterByProduct,
    filterByBrand,
    loadingUsers,
    loadingMessages,
  ]);

  const [deployments, setDeployments] = useState([]);
  const [loadingDeployments, setLoadingDeployments] = useState(true);
  const [deploymentsApiUrl, setDeploymentsApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/deployment-types/`
  );
  const [productsMeta, setProductsMeta] = useState([]);
  const [loadingProductsMeta, setLoadingProductsMeta] = useState(true);
  const [productsMetaApiUrl, setProductsMetaApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/product-meta/`
  );
  const [selectedProduct, setSelectedProduct] = useState("All");

  useEffect(() => {
    getData(
      deploymentsApiUrl,
      setDeploymentsApiUrl,
      setLoadingDeployments,
      setDeployments
    );
  }, [deploymentsApiUrl]);

  useEffect(() => {
    getData(
      productsMetaApiUrl,
      setProductsMetaApiUrl,
      setLoadingProductsMeta,
      setProductsMeta
    );
  }, [productsMetaApiUrl]);

  // get unique product_names
  /*const uniqueProducts = [
    ...new Map(
      productsMeta.map((item) => [item["product_name"], item])
    ).values(),
  ];*/
  // get unique product_names
  const uniqueProducts = [
    ...new Map(
      productsMeta
        .filter((a) => a.type === "product")
        .map((item) => [item["product_name"], item])
    ).values(),
  ];

  const userFullName = `${window.localStorage.getItem(
    "firstname"
  )} ${window.localStorage.getItem("lastname")}`;

  const filteredBrands = productsMeta.filter((item) => {
    // filter the meta data and remove
    // any items that are not a type of product
    return item.type === "product";
  });

  return (
    <section className="filters">
      <div className="filters__filter">
        <div className="filters__pair">
          <div className="filters__category">
            {loadingProductsMeta ? (
              <Labeledoader label="Loading products..." />
            ) : (
              <ProductFilter
                id="product-list"
                name="Product"
                data={uniqueProducts}
                setFilterByProduct={setFilterByProduct}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            )}
          </div>
          <div className="filters__group-product-meta">
            {loadingProductsMeta ? (
              <Labeledoader label="Loading brands..." />
            ) : (
              <BrandFilter
                id="brand-list"
                name="Product"
                data={filteredBrands}
                setFilterByBrand={setFilterByBrand}
                selectedProduct={selectedProduct}
              />
            )}
          </div>
        </div>
      </div>
      <div className="filters__filter">
        <div className="filters__pair">
          <div className="filters__category">
            {loadingDeployments ? (
              <Labeledoader label="Loading deployments..." />
            ) : (
              <DeploymentFilter
                id="deployments-filter"
                name="Deployment"
                data={deployments}
                setFilterByDeployment={setFilterByDeployment}
              />
            )}
          </div>
          <div id="states" className="filters__deployment d-none">
            {/* if state is selecte, make this active */}
            {loadingStates ? (
              <Labeledoader label="Loading states..." />
            ) : (
              <Filter name="State" data={states} />
            )}
          </div>
        </div>
      </div>
      <div className="filters__filter">
        {loadingUsers ? (
          <Labeledoader label="Loading users..." />
        ) : (
          <UserFilter
            id="user-filter"
            name="User"
            data={users}
            loggedInUser={userFullName}
            setFilterByUsers={setFilterByUsers}
          />
        )}
      </div>
    </section>
  );
}

Filter.propTypes = {
  states: array,
  loadingStates: bool,
  users: array,
  loadingUsers: bool,
  loadingMessages: bool,
};
