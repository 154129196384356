import { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import userGuide from "./AMP_Portal_Training_091521_LO12.pdf";
import {
  superUserPermissions,
  adminPermissions,
} from "../../utils/permissions";

export default function Nav({ children }) {
  const [navClass, setNavClass] = useState("d-none");
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = (event) => {
    if (!navOpen) {
      setNavOpen(true);
      setNavClass("");
    }
    if (navOpen) {
      setNavOpen(false);
      setNavClass("d-none");
    }
  };

  const handleLogout = () => {
    window.localStorage.clear();
    window.open("/login", "_self");
  };

  const canViewRoster = superUserPermissions();
  const canViewNationalMessages = adminPermissions();

  return (
    <nav>
      <div
        className="nav__hamburger"
        data-toggle-open="true"
        onClick={toggleNav}
      >
        <span className="nav__hamburger-span"></span>
        <span className="nav__hamburger-span"></span>
        <span className="nav__hamburger-span"></span>
      </div>

      <div className={`nav__link-group ${navClass}`}>
        <ul className="nav__ul">
          <li className="nav__li nav__li--hover" onClick={handleLogout}>
            Logout
          </li>

          <Link to="/" className="nav__a">
            <li className="nav__li">Dashboard</li>
          </Link>

          {canViewRoster && (
            <Link to="/roster" className="nav__a">
              <li className="nav__li">Roster</li>
            </Link>
          )}

          {canViewNationalMessages && (
            <Link to="/national-messages" className="nav__a">
              <li className="nav__li">National Messages</li>
            </Link>
          )}

          <Link to={userGuide} target="_blank" className="nav__a">
            <li className="nav__li">FAQ</li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}
