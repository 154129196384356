import React from "react";
import { useState, Suspense, useEffect } from "react";
import "./style.scss";
import { NationalMsgPreivew } from "./components/NationalMsgPreview";
import Template from "../Template";
import Modal from "../../components/Modal";
import LabeledLoader from "../../components/LabeledLoader";
import { getData, getAuthenticatedUser } from "../../utils/requests";
import Filter from "./components/Filter";
import Button from "../../components/Button";
import { adminPermissions } from "../../utils/permissions";
import { NationalMsgListItem } from "./components/NationalMsgListItem";
import { reactKeyGenerator } from "../../utils";
import axios from "axios";
import { array, func, string } from "prop-types";

export default function NationalMessages() {
  const [authUser, setAuthUser] = useState({});
  const canViewNationalMessages = adminPermissions();
  const [clearForm, setClearForm] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productsApiUrl, setProductsApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/meta-by-product/?type=product`
  );
  const [brandSelected, setBrandSelected] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brandChanged, setBrandChanged] = useState(false);
  const [filtered, setFiltered] = useState(false);

  //loading Messages
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messageApiUrl, setMessageApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/nationalmessage/?deployment=national&proudct_brand_id=${selectedBrand}`
  );
  //loading states
  const [loadingStates, setLoadingStates] = useState(true);
  const [states, setStates] = useState([]);
  const statesApiURL = `${process.env.REACT_APP_API_HOST}/api/states/`;
  const [currentStatesURL, setCurrentStatesURL] = useState(statesApiURL);

  // Modal sates
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalbodyText, setModalBodyText] = useState("");
  const [modalbodyTextTop, setModalBodyTextTop] = useState("");
  const [modalClassName, setModalClassName] = useState("d-none");
  // first button
  const [modalButtonOneText, setModalButtonOneText] = useState("");
  const [modalButtonOneType, setModalButtonOneType] = useState("");
  const [modalButtonOneClassName, setModalButtonOneClassName] = useState("");
  const [modalButtonOneOnClick, setModalButtonOneOnClick] = useState();
  const buttonstate = ""; //enable or disable
  // second button
  const [modalButtonTwoText, setModalButtonTwoText] = useState("");
  const [modalButtonTwoType, setModalButtonTwoType] = useState("");
  const [modalButtonTwoClassName, setModalButtonTwoClassName] = useState("");
  const [modalButtonTwoOnClick, setModalButtonTwoOnClick] = useState();
  // modal children
  // Using this as a string that will be set as an object Key
  // The currect actie key will be displayed in the modal window
  const [modalChildren, setModalChildren] = useState();
  const modalChildrenContent = {
    preview: <NationalMsgPreivew />,
  };
  const currentModalChildren = modalChildrenContent[modalChildren] || null;

  const [notification, setNotification] = useState("");
  const [notificationClass, setNotificationClass] = useState("d-none");
  const resetNotifications = () => {
    // reset notification to blank
    setNotification("");
    setNotificationClass("");
  };

  const [productDropdownDisabled, setProductDropdownDisabled] = useState(false);
  const [brandDropdownDisabled, setBrandDropdownDisabled] = useState(false);
  // loading users
  const [loadingUsers, setLoadingUsers] = useState(true); // bool: when true, we're fetching the list of users
  const [users, setUsers] = useState([]);
  const [userApiUrl, setUserApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/user/`
  );

  useEffect(() => {
    getData(userApiUrl, setUserApiUrl, setLoadingUsers, setUsers);
  }, [userApiUrl]);

  const headers = {
    "content-type": "application/json",
  };

  useEffect(() => {
    setAuthUser(getAuthenticatedUser);
  }, []);

  useEffect(() => {
    getData(productsApiUrl, setProductsApiUrl, setLoadingProducts, setProducts);
  }, [productsApiUrl]);

  useEffect(() => {
    getData(messageApiUrl, setMessageApiUrl, setLoadingMessages, setMessages);
  }, [messageApiUrl]);

  //loading states
  useEffect(() => {
    getData(currentStatesURL, setCurrentStatesURL, setLoadingStates, setStates);
  }, [currentStatesURL]);

  const handleEditProperties = () => {
    window.open(`/national-messages/edit/?id=${selectedBrand}`, "_self");
  };
  const [buttonState, setButtonState] = useState("");
  const initButtonState = () => {
    messages.filter((a) => a.proudct_brand_id === selectedBrand).length > 0
      ? setButtonState("")
      : setButtonState("disable");
  };
  const onBrandChange = () => {};
  return (
    <>
      {canViewNationalMessages ? (
        <Template>
          <section className="nationalmsg">
            <h1 className="d-none">National Messages</h1>
            <section className="nationalmsg__header">
              <div className="nationalmsg__products">
                {loadingProducts ? (
                  <LabeledLoader label="Loading products..." />
                ) : (
                  <>
                    <div className="nationalMsg__fields">
                      <Filter
                        name="Products"
                        data={products}
                        setBrandSelected={setBrandSelected}
                        setSelectedBrand={setSelectedBrand}
                        selectedBrand={selectedBrand}
                        setBrandChanged={setBrandChanged}
                        brandChanged={brandChanged}
                        productDropdownDisabled={productDropdownDisabled}
                        brandDropdownDisabled={brandDropdownDisabled}
                        onBrandChange={onBrandChange}
                      />
                    </div>
                    <div>
                      {brandSelected &&
                      messages.filter(
                        (a) => a.proudct_brand_id === selectedBrand
                      ).length > 0 ? (
                        <div
                          id="nationalMsg__btns"
                          className="nationalMsg__btns"
                        >
                          <Button
                            id="show-edit-btns"
                            text="Edit Priorities"
                            onClick={handleEditProperties}
                            disabled={buttonState}
                          />
                          <div
                            id="edit-buttons"
                            className="nationalMsg__edit-btns d-none"
                          ></div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            </section>
            <div id="nationalmsg__list" className="nationalmsg__list">
              {loadingMessages &&
              brandSelected &&
              messages.filter((a) => a.proudct_brand_id === selectedBrand)
                .length > 0 ? (
                <LabeledLoader label="Loading national messages..." />
              ) : (
                messages
                  .filter((a) => a.proudct_brand_id === selectedBrand)
                  .map((item, i) => {
                    let owner;
                    users.forEach((user) => {
                      if (user.id === item.owner_id) {
                        owner = user;
                        return;
                      }
                    });
                    return (
                      <NationalMsgListItem
                        key={`NationalMsgListItem${i}__${reactKeyGenerator}`}
                        isMain={"main"}
                        data={item}
                        owner={owner}
                        filtered={filtered}
                        selectedBrand={selectedBrand}
                      />
                    );
                  })
              )}
            </div>
          </section>

          <Modal
            headerText={modalHeaderText}
            bodyTextTop={modalbodyTextTop}
            bodyText={modalbodyText}
            className={modalClassName}
            buttonOne={[
              modalButtonOneText,
              modalButtonOneType,
              modalButtonOneClassName,
              modalButtonOneOnClick,
            ]}
            buttonTwo={[
              modalButtonTwoText,
              modalButtonTwoType,
              modalButtonTwoClassName,
              modalButtonTwoOnClick,
            ]}
          >
            {currentModalChildren}
          </Modal>
        </Template>
      ) : (
        window.open("/", "_self")
      )}
    </>
  );
}

NationalMessages.propTypes = {
  priorities: array,
  messages: array,
  states: array,
  selectedBrand: string,
  setCurrentBrand: func,
};
