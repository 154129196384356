import React from "react";
import { bool, array, func } from "prop-types";
import Labeledoader from "../LabeledLoader";
import Button from "../Button";
import { Heading } from "../UILib";
import { superUserPermissions } from "../../utils/permissions";
import {
  DeploymentReset,
  DeploymentStateUpdate,
} from "../../utils/DeploymentStateUpdate";

export default function DeploymentTypes({
  loading,
  options,
  setModalClassName,
  setModalHeaderText,
  setModalBodyText,
  setModalBodyTextTop,
  setModalButtonOneText,
  setModalButtonOneType,
  setModalButtonOneClassName,
  setModalButtonOneOnClick,
  setModalButtonTwoText,
  setModalButtonTwoType,
  setModalButtonTwoClassName,
  setModalButtonTwoOnClick,
  setModalChildren,
  setNotification,
  setNotificationClass,
}) {
  const isSuperUser = superUserPermissions();
  let deploymentType = "";
  // modal button events
  const modalConfirmOnClick = (event) => {
    //only updates state, district and City options aftter confirm
    //see ../../utils/DeploymentStateUpdate.jsx
    DeploymentStateUpdate(deploymentType);

    setModalClassName("d-none");
    // re-enable scrolling
    document
      .querySelector("#create-new-message")
      .classList.remove("msg--disable-scroll");
  };

  // modal button two click
  const modalCancelOnClick = () => {
    // clear everything on cancel : update: removed this behavior
    //@see ../../utils/DeploymentStateUpdate.jsx
    DeploymentReset(deploymentType);

    setModalClassName("d-none");
    document
      .querySelector("#create-new-message")
      .classList.remove("msg--disable-scroll");
  };

  const toggleDeploymentRadiosOnClick = (event) => {
    // toggle radios
    document
      .querySelectorAll('[data-deployments="deployment-option"]')
      .forEach((element) => {
        element.checked = false;
      });
    event.target.checked = true;
    // reset Checkboxes
    document
      .querySelectorAll("[data-type=deploymentOption]")
      .forEach((item) => {
        item.checked = false;
      });
    // clear selected options
    document.querySelectorAll(".deployment-selections").forEach((elm) => {
      elm.innerHTML = "";
    });
  };

  const handleDeploymentClickEvent = (type) => {
    deploymentType = type;
    // Check if the radio has been clicked
    if (!document.querySelector(`input[name="${type}"]`).checked) {
      // show error
      setNotification(
        "You must select a deployment Type in order to view the options."
      );
      setNotificationClass("notification--show");
      // reset after timeout otherwise the change
      // happens too fast and the modal will
      // not work again
      setTimeout(resetNotifications, 5500);
      return;
    }
    // show the modal
    setModalClassName();
    setModalHeaderText("Select Deployment Options");
    setModalBodyTextTop(
      "Please check all deployment options for this message, then click Confirm."
    );
    setModalBodyText("");
    setModalButtonOneText("Confirm");
    setModalButtonOneType("button");
    setModalButtonOneClassName("");
    setModalButtonOneOnClick(() => modalConfirmOnClick);
    setModalButtonTwoText("Cancel");
    setModalButtonTwoType("button");
    setModalButtonTwoClassName("");
    setModalButtonTwoOnClick(() => modalCancelOnClick);

    // scroll to top and disable scrolling
    // doing this to stop background from scrolling
    document.querySelector("#create-new-message").scrollTo(0, 0);
    document
      .querySelector("#create-new-message")
      .classList.add("msg--disable-scroll");

    if (type === "State") {
      setModalChildren("stateDeployments");
    }
    if (type === "District") {
      setModalChildren("districtDeployments");
    }
    if (type === "City/CBSA") {
      setModalChildren("cbsaDeployments");
    }
  };

  const resetNotifications = () => {
    // reset notification to blank
    setNotification("");
    setNotificationClass("");
  };

  return (
    <section className="deployment">
      <div className="deployment__content">
        <Heading type="h3" align="left">
          Select Deployment Option:<sup>*</sup>
        </Heading>

        <div className="deployment__options">
          {loading ? (
            <Labeledoader label="Loading deployments..." />
          ) : (
            options.map((item) => {
              // Disable to All view since we only
              // use this as a filter and not an option.
              if (item.name === "All") {
                return;
              }
              // only allow Admins to view "National" as an option
              if (item.name === "Naional" && !isSuperUser) {
                return;
              }
              return (
                <div
                  className="deployment__options-wrapper"
                  key={`${item.key}-${item.name}`}
                >
                  <div className="deployment__options-item">
                    <input
                      type="radio"
                      data-deployments="deployment-option"
                      data-newmessageradio={true}
                      data-type="deployment"
                      value={item.name}
                      id={item.name}
                      name={item.name}
                      onClick={toggleDeploymentRadiosOnClick}
                    />
                    <label>{item.name}</label>
                  </div>
                  {item.name === "State" && (
                    <Button
                      text="Select"
                      type="button"
                      className="deployment__button"
                      onClick={() => {
                        handleDeploymentClickEvent(item.name);
                      }}
                    />
                  )}
                  {item.name === "District" && (
                    <Button
                      text="Select"
                      type="button"
                      className="deployment__button"
                      onClick={() => {
                        handleDeploymentClickEvent(item.name);
                      }}
                    />
                  )}
                  {item.name === "City/CBSA" && (
                    <Button
                      text="Select"
                      type="button"
                      className="deployment__button"
                      onClick={() => {
                        handleDeploymentClickEvent(item.name);
                      }}
                    />
                  )}
                  {item.name === "City/CBSA" ? (
                    <div
                      className={`cbsa-selections deployment-selections`}
                    ></div>
                  ) : (
                    <div
                      className={`${item.name}-selections deployment-selections`}
                    ></div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </section>
  );
}

DeploymentTypes.propTypes = {
  loading: bool,
  setModalHeaderText: func,
  setModalBodyText: func,
  setModalBodyTextTop: func,
  setModalButtonOneText: func,
  setModalButtonOneType: func,
  setModalButtonOneClassName: func,
  setModalButtonOneOnClick: func,
  setModalButtonTwoText: func,
  setModalButtonTwoType: func,
  setModalButtonTwoClassName: func,
  setModalButtonTwoOnClick: func,
  setModalChildren: func,
  states: array,
  cbsa: array,
  districs: array,
};
