import React, { useEffect, useState } from "react";
import "./style.scss";
import Users from "./components/Users";
import {
  AddUser,
  ConfirmAddUser,
  EditUser,
  EditUserConfirm,
  DeleteUser,
} from "./components/modalContent";
import Template from "../Template";
import { RosterFooter } from "../../components/Footer";
import Modal from "../../components/Modal";
import { NotificationSlider } from "../../components/Notification";
import {
  adminPermissions,
  superUserPermissions,
} from "../../utils/permissions";
import { post } from "../../utils/requests";

const authUserIsAdmin = adminPermissions();
const canViewRoster = superUserPermissions();

export default function Roster() {
  // Loading users
  const [loadingUsers, setLoadingUsers] = useState(true);
  // notifications
  const [notification, setNotification] = useState("");
  const [notificationClass, setNotificationClass] = useState("d-none");
  // Modal sates
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalbodyText, setModalBodyText] = useState("");
  const [modalbodyTextTop, setModalBodyTextTop] = useState("");
  const [modalClassName, setModalClassName] = useState("d-none");
  // first button
  const [modalButtonOneText, setModalButtonOneText] = useState("");
  const [modalButtonOneType, setModalButtonOneType] = useState("");
  const [modalButtonOneClassName, setModalButtonOneClassName] = useState("");
  const [modalButtonOneOnClick, setModalButtonOneOnClick] = useState();
  // second button
  const [modalButtonTwoText, setModalButtonTwoText] = useState("");
  const [modalButtonTwoType, setModalButtonTwoType] = useState("");
  const [modalButtonTwoClassName, setModalButtonTwoClassName] = useState("");
  const [modalButtonTwoOnClick, setModalButtonTwoOnClick] = useState();
  // modal children
  // Using this as a string that will be set as an object Key
  // The currect actie key will be displayed in the modal window
  const [modalChildren, setModalChildren] = useState();
  const [userInEdit, setUserInEdit] = useState();
  // AddNewUser
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState({});

  useEffect(function persistentForm() {
    // creating and updating users
    window.localStorage.setItem("addUserFirstname", firstname);
    window.localStorage.setItem("addUserLastname", lastname);
    window.localStorage.setItem("addUserEmail", email);
    window.localStorage.setItem("userInEdit", JSON.stringify(userInEdit)); // user details whe editiny a user
    window.localStorage.setItem(
      "addOrEditPermissions",
      JSON.stringify(permissions)
    );
  });

  const resetNotifications = () => {
    // reset notification to blank
    setNotification("");
    setNotificationClass("");
  };

  // Cancel button was clicked for either modal type
  const handleModalCancel = () => {
    setModalClassName("d-none");
  };

  const handleConfirmNewUser = () => {
    setModalClassName("");
    setModalHeaderText("Add New User");
    setModalBodyTextTop(
      "In order to add this new user to your roster, please review the following details to ensure they are correct, then click “Confirm.”"
    );
    setModalBodyText("");
    setModalButtonOneText("Confirm");
    setModalButtonOneType("button");
    setModalButtonOneOnClick(() => createNewUser);
    setModalButtonTwoText("Edit");
    setModalButtonTwoType("button");
    setModalButtonTwoOnClick(() => handleAddNewUser);
    setModalChildren("confirmadd");
  };

  const handleAddNewUser = () => {
    setModalClassName("");
    setModalHeaderText("Add New User");
    setModalBodyTextTop("");
    setModalBodyText("");
    setModalButtonOneText("Add User");
    setModalButtonOneType("button");
    setModalButtonOneOnClick(() => handleConfirmNewUser);
    setModalButtonTwoText("Cancel");
    setModalButtonTwoType("button");
    setModalButtonTwoOnClick(() => handleModalCancel);
    setModalChildren("adduser");
  };

  const createNewUser = () => {
    const URL = `${process.env.REACT_APP_API_HOST}/api/user/`;
    const firstname = window.localStorage.getItem("addUserFirstname");
    const lastname = window.localStorage.getItem("addUserLastname");
    const email = window.localStorage.getItem("addUserEmail");

    const permissions = JSON.parse(
      window.localStorage.getItem("addOrEditPermissions")
    );
    const { is_admin, is_superuser } = permissions;

    const payload = {
      first_name: firstname,
      last_name: lastname,
      email: email,
      roster_upload: false,
      is_admin: is_admin,
      is_superuser: is_superuser,
    };
    // callback
    const clearAddUserVars = (response) => {
      setFirstname("");
      setLastname("");
      setEmail("");
      setModalChildren();
      setPermissions({});
      setModalClassName("d-none");

      if (response.status !== 201) {
        // show error
        setNotification(
          "There was a problem creating this user. This user may already exist, or something was missing."
        );
        setNotificationClass("notification--show");
        // reset after timeout otherwise the change
        // happens too fast and the modal will
        // not work again
        setTimeout(resetNotifications, 5500);
        return;
      }

      // show success
      setNotification("User Created!");
      setNotificationClass("notification--show");
      // reset after timeout otherwise the change
      // happens too fast and the modal will
      // not work again
      setTimeout(resetNotifications, 5500);
    };

    post(URL, payload, clearAddUserVars);
  };

  // Set each type of modal child and an object of Components
  const modalChildrenContent = {
    adduser: (
      <AddUser
        firstname={firstname}
        lastname={lastname}
        email={email}
        setFirstname={setFirstname}
        setLastname={setLastname}
        setEmail={setEmail}
        setPermissions={setPermissions}
        authUserIsAdmin={authUserIsAdmin}
      />
    ),
    confirmadd: (
      <ConfirmAddUser
        firstname={firstname}
        lastname={lastname}
        email={email}
        permission={permissions}
      />
    ),
    editUser: (
      <EditUser
        user={userInEdit}
        setPermissions={setPermissions}
        authUserIsAdmin={authUserIsAdmin}
      />
    ),
    editUserConfirm: (
      <EditUserConfirm user={userInEdit} permissions={permissions} />
    ),
    deleteUser: <DeleteUser user={userInEdit} />,
  };

  // get the current Modal Child to display in the modal window
  const currentModalChildren = modalChildrenContent[modalChildren] || null;

  return (
    <Template>
      {canViewRoster ? (
        <>
          <section className="roster container">
            <div className="d-none">
              <h1>The Roster</h1>
            </div>
            <section className="roster__userlist">
              <Users
                loadingUsers={loadingUsers}
                setLoadingUsers={setLoadingUsers}
                setModalClassName={setModalClassName}
                setModalHeaderText={setModalHeaderText}
                setModalBodyText={setModalBodyText}
                setModalBodyTextTop={setModalBodyTextTop}
                setModalButtonOneText={setModalButtonOneText}
                setModalButtonOneType={setModalButtonOneType}
                setModalButtonOneClassName={setModalButtonOneClassName}
                setModalButtonOneOnClick={setModalButtonOneOnClick}
                setModalButtonTwoText={setModalButtonTwoText}
                setModalButtonTwoType={setModalButtonTwoType}
                setModalButtonTwoClassName={setModalButtonTwoClassName}
                setModalButtonTwoOnClick={setModalButtonTwoOnClick}
                setModalChildren={setModalChildren}
                setUserInEdit={setUserInEdit}
                permissions={permissions}
                setPermissions={setPermissions}
                setNotification={setNotification}
                setNotificationClass={setNotificationClass}
                resetNotifications={resetNotifications}
              />
            </section>
          </section>

          <Modal
            headerText={modalHeaderText}
            bodyTextTop={modalbodyTextTop}
            bodyText={modalbodyText}
            className={modalClassName}
            buttonOne={[
              modalButtonOneText,
              modalButtonOneType,
              modalButtonOneClassName,
              modalButtonOneOnClick,
            ]}
            buttonTwo={[
              modalButtonTwoText,
              modalButtonTwoType,
              modalButtonTwoClassName,
              modalButtonTwoOnClick,
            ]}
          >
            {currentModalChildren}
          </Modal>

          <NotificationSlider
            type="slider"
            children={notification}
            className={notificationClass}
          />

          <RosterFooter showNewUserModalOnClick={handleAddNewUser} />
        </>
      ) : (
        window.open("/", "_self")
      )}
    </Template>
  );
}
