import { array, func } from "prop-types";

export const sortMessages = (messages, messageSortType, messageSortOption) => {
  let sortedMessages = messages;
  if (messageSortOption !== "None") {
    sortedMessages = messages.sort(function (msgA, msgB) {
      // Dates and strings cannot be compared the same way
      if (messageSortType === "Date") {
        // make a date object for each date and compare
        const dateA = new Date(msgA[messageSortOption]);
        const dateB = new Date(msgB[messageSortOption]);
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
      }

      if (messageSortType === "Priority") {
        // make a date object for each date and compare
        const str1 = msgA["priority"].toLowerCase();
        const str2 = msgB["priority"].toLowerCase();
        if (str1 < str2) {
          return -1;
        }
        if (str1 > str2) {
          return 1;
        }
      }
      return 0;
    });
  }
  return sortedMessages;
};

sortMessages.propTypes = {
  messages: array,
  setMessageSortOption: func,
  setMessageSortDirection: func,
};
