import {array, bool, func, string} from "prop-types";
import {reactKeyGenerator} from "../../../../../utils";
import {useState, useEffect} from "react";
import Select from "react-select";
import "./style.scss";
import {Input} from "react-select/animated";
import Filter from "../../../../Dashboard/components/Filters/Filter";

export default function StateFilter({
                                           name, // string: the name of the filter type
                                           data, // array: the data for the filter
                                           selectedState,
                                           setHideRow,
                                           setFilteredState,
                                           onStateFilterChange
                                       }) {

    const [state, setState] = useState();
    const [checked, setChecked] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [filtered, setFiltered] = useState('style="display:block;"')


    const handleStateFilterChange = (selectedOption) => {
        setSelectedOption(selectedOption.value);
        console.log(selectedOption)
        setFilteredState(selectedOption)

        /*let filtered = districtList.filter( (el, index)=>{
            if(selectedOption.includes(el)){
                let targetEl = document.querySelector(`.modal__checkbox-row.row-${el.id}`)
                targetEl.style.display ='block';
            }
            return !selectedOption.includes(el)
        })


        filtered.forEach((item, index) =>{
            console.log(`hide .modal__checkbox-row.row-${item.id}`)
           let targetEl = document.querySelector(`.modal__checkbox-row.row-${item.id}`)
                targetEl.style.display ='none';
        })*/
        document.querySelectorAll('.modal__checkbox-row')
            .forEach((item, index) => {
                let targetEl = item;
                if (selectedOption.length == 0) {
                    item.style.display = 'block'
                    document.querySelector('#checkedFilter').disabled = false;
                } else {
                    item.style.display = 'none'
                    document.querySelector('#checkedFilter').disabled = true;
                }

            })
        selectedOption.forEach((item, index) => {
            let targetEl = document.querySelector(`.modal__checkbox-row.row-${item.id}`);
            targetEl.style.display = 'block'
        })


        onStateFilterChange(filtered)
    }


    const handleStateChange = () => {
        setChecked(!checked);
        let updatedRow = [];
        if (!checked === true) {
            document.querySelectorAll('.modal__checkbox-row').forEach((item, index) => {
                let cInput = item.firstChild;
                let rowItem = item

                if (cInput.checked === false) {
                    rowItem.style.display = 'none'
                    rowItem.classList.add('hidden')
                    rowItem.classList.remove('hidden')
                }else {
                    rowItem.style.display = 'block'
                    rowItem.classList.add('show')
                    rowItem.classList.remove('hidden')
                    updatedRow = [...updatedRow, rowItem];
                }

            })
            setHideRow(updatedRow);

        } else {
            document.querySelectorAll('.modal__checkbox-row').forEach((item, index) => {
                let cInput = item.firstChild;
                let rowItem = item
                rowItem.style.display = 'block'

            })
            setHideRow(updatedRow);
        }

    };

    const
        FilterStyles = {
            valueContainer: (base) => ({
                ...base,
                maxHeight: 70,
                overflowY: "auto"
            }),
            control: (base, state) => ({
                ...base,
                borderRadius: state.isFocused ? "0 0 0 0" : 0,
                borderColor: state.isFocused ? "#333" : "#333",
            }),
            menu: (base, state) => ({
                ...base,
                borderRadius: 0,
                hyphens: "auto",
                marginTop: 0,
                textAlign: "left",
                wordWrap: "break-word",
                background: state.isSelected ? "#eee" : "#fff",
            }),

            menuList: (base) => ({
                ...base,
                padding: 0,
            }),
        };


    const productBrand = data.map(function (item) {
        return {
            product: item.product_name,
            brand: data
                .filter(function (o) {
                    return o.product_name === item.product_name;
                })
                .reduce(function (r, a) {
                    if (r.product_name === a.product_name) {
                        r = {...r, a};
                    } else {
                        r = a || {};
                    }
                    return r;
                }, Object.create(null)),
        };
    });


    // select drop down meta
    const stateList = [
        ...new Map(data.map((item) => [item["state"], item])).values(),
    ];

    return (
        <>
            <div className="district_filters__filter">
                <div className="district_filters__filter_options">
                    <label>Filter Checked States:
                        <input type="checkbox"
                               checked={checked}
                               onChange={handleStateChange}
                               id="checkedFilter"
                        />
                    </label>
                </div>
                <div className="district_filters__filter_options">
                    <label>Filter by State Description:
                        <Select
                            name="filter2"
                            key={`state_unique_select_key_f__${reactKeyGenerator}`}
                            placeholder="Select State"
                            width="250"
                            options={stateList}
                            onChange={handleStateFilterChange}
                            getOptionLabel={(x) => x.state + x.state_name}
                            getOptionValue={(x) => x.id}
                            styles={FilterStyles}
                            closeMenuOnSelect={false}
                            isMulti
                            formatOptionLabel={function (data) {
                                return (
                                    <span dangerouslySetInnerHTML={{__html: data.state_name}}/>
                                );
                            }}
                        />
                    </label>
                </div>
            </div>
        </>
    );
}

StateFilter.propTypes = {
    name: string,
    data: array,
    setDisplayFiltered: func
};
