import { lazy, Suspense, useState, useEffect } from "react";
import { func, string } from "prop-types";
import LabeledLoader from "../../../../components/LabeledLoader";
import { getData } from "../../../../utils/requests";
import { sortMessages } from "../../../../utils/messageMethods";
const Message = lazy(() => import("../Message"));

export default function Messages({
  loadingMessages,
  setLoadingMessages,
  messageSortType,
  messageSortOption,
  messageSortDirection,
  setModalClassName,
  setModalHeaderText,
  setModalBodyText,
  setModalBodyTextTop,
  setModalButtonOneText,
  setModalButtonOneType,
  setModalButtonOneClassName,
  setModalButtonOneOnClick,
  setModalButtonTwoText,
  setModalButtonTwoType,
  setModalButtonTwoClassName,
  setModalButtonTwoOnClick,
  setModalChildren,
  users,
  setNotification,
  setNotificationClass,
  resetNotifications,
}) {
  const [refreshMessageList, setRefreshMessageList] = useState(false);
  const [messages, setMessages] = useState([]); // array of messages
  const initialMessageApiURL = `${process.env.REACT_APP_API_HOST}/api/message/`; // initial API url for messages
  const [messagesApiURL, setMessagesApiURL] = useState(initialMessageApiURL);
  // pass messages to sort method before mapping
  let sortedMessages = sortMessages(
    messages,
    messageSortType,
    messageSortOption,
    messageSortDirection
  );

  useEffect(() => {
    if (refreshMessageList) {
      setMessagesApiURL(initialMessageApiURL);
      setMessages([]); // delete the messages array
      setRefreshMessageList(false);
    }
    if (loadingMessages && !refreshMessageList) {
      getData(
        messagesApiURL,
        setMessagesApiURL,
        setLoadingMessages,
        setMessages
      );
    }
    // At any pont, a user can trigger aloading messages state
    // this will wipe out the current state and
    // re-pull the data from the API
  }, [
    loadingMessages,
    setLoadingMessages,
    initialMessageApiURL,
    messagesApiURL,
    refreshMessageList,
  ]);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <section className="dashboard__messages">
        {loadingMessages ? (
          <LabeledLoader label="Loading Messages..." />
        ) : (
          sortedMessages
            .slice(0)
            .reverse()
            .map((message) => {
              let owner;
              users.forEach((user) => {
                if (user.id === message.owner_id) {
                  owner = user;
                  return;
                }
              });

              return (
                <Message
                  key={`messageId-${message.id}`}
                  data={message}
                  owner={owner}
                  setRefreshMessageList={setRefreshMessageList}
                  setLoadingMessages={setLoadingMessages}
                  setModalClassName={setModalClassName}
                  setModalHeaderText={setModalHeaderText}
                  setModalBodyText={setModalBodyText}
                  setModalBodyTextTop={setModalBodyTextTop}
                  setModalButtonOneText={setModalButtonOneText}
                  setModalButtonOneType={setModalButtonOneType}
                  setModalButtonOneClassName={setModalButtonOneClassName}
                  setModalButtonOneOnClick={setModalButtonOneOnClick}
                  setModalButtonTwoText={setModalButtonTwoText}
                  setModalButtonTwoType={setModalButtonTwoType}
                  setModalButtonTwoClassName={setModalButtonTwoClassName}
                  setModalButtonTwoOnClick={setModalButtonTwoOnClick}
                  setModalChildren={setModalChildren}
                  setNotification={setNotification}
                  setNotificationClass={setNotificationClass}
                  resetNotifications={resetNotifications}
                />
              );
            })
        )}
      </section>
    </Suspense>
  );
}

Messages.propTypes = {
  messageSortType: string,
  messageSortOption: string,
  messageSortDirection: string,
  setModalClassName: func,
  setModalHeaderText: func,
  setModalBodyText: func,
  setModalBodyTextTop: func,
  setModalButtonOneText: func,
  setModalButtonOneType: func,
  setModalButtonOneClassName: func,
  setModalButtonOneOnClick: func,
  setModalButtonTwoText: func,
  setModalButtonTwoType: func,
  setModalButtonTwoClassName: func,
  setModalButtonTwoOnClick: func,
  setModalChildren: func,
  setNotification: func,
  setNotificationClass: func,
  resetNotifications: func,
};
