import "./styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import NationalMessages from "./pages/NationalMessages";
import Roster from "./pages/Roster";
import CreateEditMessage from "./pages/CreateEditMessage";
import NationalMessagesCreateEdit from "./pages/NationalMessagesCreateEdit";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/national-messages/" component={NationalMessages} />
        <Route
          exact
          path="/national-messages/edit/"
          component={NationalMessagesCreateEdit}
        />
        <Route path="/login/" component={Login} />
        <Route path="/message/" component={CreateEditMessage} />
        <Route path="/roster/" component={Roster} />
      </Switch>
    </Router>
  );
}

export default App;
