import React, { useState, useEffect } from "react";
import axios from "axios";

import "./style.scss";
import Template from "../Template";
// components
import Button from "../../components/Button";
import DeploymentTypes from "../../components/DeploymentTypes";
import Footer from "../../components/BI/Footer";
import GoLive from "../../components/GoLive";
import Permission from "../../components/Permission";
import Preview from "../../components/Preview";
import MessageTemplateInputs from "../../components/MessageTemplateInputs";
import Modal from "../../components/Modal";
import { NotificationSlider } from "../../components/Notification";
import Type from "../../components/Type";
import { Text } from "../../components/UILib";
import {
  CityCbsaOptions,
  DistrictOptions,
  MessagePreview,
  StatesOptions,
} from "./components/ModalChildren";
// utils
import { get, getData } from "../../utils/requests";
import {
  checkDateDeltas,
  checkRequiredInputs,
  checkSelectedDeploymentOptions,
} from "./service/inputValidators";
import LabeledLoader from "../../components/LabeledLoader";

// generate a new message and upload it to the database
export default function CreateEditMessage() {
  // Check for URL params...
  // If there are params, the we must fetch
  // the message data, and determine if we are editing or reviwing a message
  const params = new URLSearchParams(window.location.search);
  const msgId = params.get("id"); // get the msg ID
  const editMode = params.has("edit"); // set to edit mode
  const reviewMode = params.has("review"); // set to review mode
  const [msgData, setMsgData] = useState({});
  const [templateRendering, setTemplateRendering] = useState("");
  const [notification, setNotification] = useState("");
  const [notificationClass, setNotificationClass] = useState("d-none");
  const resetNotifications = () => {
    // reset notification to blank
    setNotification("");
    setNotificationClass("");
  };
  // Options for crating new messages
  // Determins which type of message template to display once
  // an option is seleccted form the <Types/> Compoent
  const [templateType, setTemplateType] = useState("");
  const [templateTypeSelected, setTemplateTypeSelected] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  //Product Selected for brand
  const [selectedProduct, setSelectedProduct] = useState("");
  // States
  const [states, setStates] = useState([]); // array of states
  const [loadingStates, setLoadingStates] = useState(true); // booL: true when laoding states
  const [statesApiURL, setStatesApiURL] = useState(
    `${process.env.REACT_APP_API_HOST}/api/states/`
  );

  // a preview of the created message
  const [messagePreview, setMessagePreview] = useState("");
  // Modal sates
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalbodyText, setModalBodyText] = useState("");
  const [modalbodyTextTop, setModalBodyTextTop] = useState("");
  const [modalClassName, setModalClassName] = useState("d-none");
  // first button
  const [modalButtonOneText, setModalButtonOneText] = useState("");
  const [modalButtonOneType, setModalButtonOneType] = useState("");
  const [modalButtonOneClassName, setModalButtonOneClassName] = useState("");
  const [modalButtonOneOnClick, setModalButtonOneOnClick] = useState();
  // second button
  const [modalButtonTwoText, setModalButtonTwoText] = useState("");
  const [modalButtonTwoType, setModalButtonTwoType] = useState("");
  const [modalButtonTwoClassName, setModalButtonTwoClassName] = useState("");
  const [modalButtonTwoOnClick, setModalButtonTwoOnClick] = useState();
  // modal children
  // Using this as a string that will be set as an object Key
  // The currect actie key will be displayed in the modal window
  const [modalChildren, setModalChildren] = useState();
  const handleModalCancel = () => {
    setModalClassName("d-none");
    document
      .querySelector("#create-new-message")
      .classList.remove("msg--disable-scroll");
  };

  useEffect(() => {
    getData(statesApiURL, setStatesApiURL, setLoadingStates, setStates);
  }, [statesApiURL]);

  const [deploymentOptions, setDeploymentOptions] = useState([]);
  const [loadingDeployments, setLoadingDeployments] = useState(true);

  // lifting the state of the preview because we POST messages from the NewMessage Component
  // const [messagePreview, setMessagePreview] = useState(""); // a preview of the created message
  const [constructingPreview, setConstructingPreview] = useState(false); // boolean: if true re-builds the preview message
  // event hanlder for <Preview/> changes
  const handlePreviewChangeEvent = () => {
    setConstructingPreview(true);
  };

  const handleStaticFieldChangeEvent = () =>{

  }

  const sethandleErrorEvent=(optional) =>{
    setNotification((optional==='')?"Maximum Character length reached " : optional);
    setNotificationClass("notification--show");
        // reset after timeout otherwise the change
        // happens too fast and the modal will
        // not work again
        setTimeout(resetNotifications, 5500);
  }

  // effects
  useEffect(() => {
    // trigger preview change
    setTimeout(() => {
      // useing a 2 second deplay here to allow client to
      // fill in the <options> after Edit is clicked.
      handlePreviewChangeEvent();
    }, 2500);
  }, [productSelected]);

  // funcs
  // modal button events
  const handleConfirmSubmit = () => {
    const creator_id = window.localStorage.getItem("uid");
    const owner_id = window.localStorage.getItem("uid");
    const last_editor_id = window.localStorage.getItem("uid");
    let payload;

    // todo: refactor this
    if (editMode || reviewMode) {
      payload = {
        creator_id: msgData.creator_id,
        owner_id: msgData.owner_id,
        last_editor_id: last_editor_id,
        priority: "Low", // default
        parent_product: document.querySelector("#parent-product").value,
        golive_date: document.querySelector("#go-live-date").value,
        expiration_date: document.querySelector("#expiration-date").value,
        message: messagePreview,
        approval_status:
          msgData.approval_status === 4 ? 5 : msgData.approval_status,
      };
    } else {
      payload = {
        creator_id: creator_id,
        owner_id: owner_id,
        last_editor_id: last_editor_id,
        priority: "Low", // default
        parent_product: document.querySelector("#parent-product").value,
        golive_date: document.querySelector("#go-live-date").value,
        expiration_date: document.querySelector("#expiration-date").value,
        message: messagePreview,
      };
    }

    // get template type
    document.querySelectorAll("[data-type=template]").forEach((elm) => {
      if (elm.checked) {
        payload[elm.dataset.type] = elm.name;
      }
    });
    // get deployment type
    document.querySelectorAll("[data-type=deployment]").forEach((elm) => {
      if (elm.checked) {
        payload[elm.dataset.type] = elm.name;
      }
    });
    // get permission type
    document.querySelectorAll("[data-type=permission]").forEach((elm) => {
      if (elm.checked) {
        payload[elm.dataset.type] = elm.name;
      }
    });
    // get message options
    document.querySelectorAll("option[data-newmessage=true]").forEach((elm) => {
      if (elm.selected) {
        payload[elm.dataset.djangofield] = elm.value;
      }
    });
    // get message inputs
    document.querySelectorAll("input[data-newmessage=true]").forEach((elm) => {
      payload[elm.dataset.djangofield] = elm.value;
    });
    //102421
    // get message state selection
    try {
      payload["state"] =
        document.querySelector("[data-deployment-option-state]") !== null
          ? JSON.parse(
              document
                .querySelector("[data-deployment-option-state]")
                .getAttribute("data-deployment-option-state")
            )
          : JSON.parse(
              document
                .querySelector("div[data-deployment-state-selected]")
                .getAttribute("data-deployment-state-selected")
            );
      if (payload["deployment"] !== "State") {
        payload["state"] = JSON.parse("{}");
      }
    } catch (e) {}

    try {
      payload["district"] =
        document.querySelector("[data-deployment-option-state]") !== null
          ? JSON.parse(
              document
                .querySelector("[data-deployment-option-state]")
                .getAttribute("data-deployment-option-state")
            )
          : JSON.parse(
              document
                .querySelector("div[data-deployment-districts-selected]")
                .getAttribute("data-deployment-districts-selected")
            );

      if (payload["deployment"] !== "District") {
        payload["district"] = JSON.parse("{}");
      }
    } catch (e) {}

    try {
      payload["cbsa"] =
        document.querySelector("[data-deployment-option-state]") !== null
          ? JSON.parse(
              document
                .querySelector("[data-deployment-option-state]")
                .getAttribute("data-deployment-option-state")
            )
          : JSON.parse(
              document
                .querySelector("div[data-deployment-cbsa-selected]")
                .getAttribute("data-deployment-cbsa-selected")
            );
      if (payload["deployment"] !== "City/CBSA") {
        payload["cbsa"] = JSON.parse("{}");
      }
    } catch (e) {}

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
      },
    };

    const submitData = async () => {
      let url = `${process.env.REACT_APP_API_HOST}/api/message/`;

      try {
        if (editMode || reviewMode) {
          payload["id"] = msgId;
          url = url + msgId + "/";
          await axios.put(`${url}`, payload, headers).then((res) => {
            // Success - Redirect to Dashboard
            window.open("/", "_self");
          });
        } else {
          await axios.post(`${url}`, payload, headers).then((res) => {
            // Success - Redirect to Dashboard
            window.open("/", "_self");
          });
        }
      } catch (error) {
        console.log(error);
        setModalClassName("d-none");
        // show error
        setNotification("There was a problem submitting your message.");
        setNotificationClass("notification--show");
        // reset after timeout otherwise the change
        // happens too fast and the modal will
        // not work again
        setTimeout(resetNotifications, 5500);
      }
    };
    submitData();

    // re-enable scrolling
    document
      .querySelector("#create-new-message")
      .classList.remove("msg--disable-scroll");
  };

  useEffect(() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
      },
    };
    const getDeploymentsOptions = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_HOST}/api/deployment-types/`,
            headers
          )
          .then((res) => {
            const data = res.data.results;
            setDeploymentOptions(data);
          });
        setLoadingDeployments(false);
      } catch (error) {
        console.log(error);
      }
    };
    getDeploymentsOptions();
  }, []);

  /***
   * useEffect for editing a message
   */
  useEffect(() => {
    // if we're editing or reviewing,
    // get the message data
    if (editMode || reviewMode) {
      const URL = `${process.env.REACT_APP_API_HOST}/api/message/${msgId}/`;
      const callback = (response) => {
        if (response.status !== 200) {
          // show error
          setNotification("There was a problem getting your message data.");
          setNotificationClass("notification--show");
          // reset after timeout otherwise the change
          // happens too fast and the modal will
          // not work again
          setTimeout(resetNotifications, 2500);
          return;
        }
        // set the data in state
        const data = response.data;
        //@todo :find solution later
        handleEdit(data);
        setTemplateRendering("d-none");
      };
      get(URL, callback);
    }
  }, [msgId, editMode, reviewMode, productSelected]);

  /***
   * useEffect for handle edit
   * edit a message data
   * @param data
   */

  const handleEdit = (data) => {
    setTemplateRendering("d-none");
    setMsgData(data);

    //const data = msgData;
    setTemplateType(msgData.template);
    setTemplateTypeSelected(true);
    document.querySelectorAll("[data-type=template]").forEach((elm) => {
      if (elm.name === data["template"]) {
        elm.checked = true;
        elm.value = data["template"];
      }
    });

    setTimeout(function () {
      //postinsql(topicId);
      document
        .querySelectorAll("[data-djangofield=parent-product]")
        .forEach((elm) => {
          if (elm.value === data.parent_product) {
            elm.selected = true;
            setSelectedProduct(data.parent_product);
            setProductSelected(true);
          }
        });
    }, 1000);

    setTimeout(function () {
      document.querySelector("body").setAttribute("content-mode", "edit");
      document.querySelector("#go-live-date").value = data["expiration_date"];
      document.querySelector("#go-live-date").value = data["golive_date"];

      document.querySelectorAll("[data-djangofield=product]").forEach((elm) => {
        if (elm.value === data.product) {
          elm.selected = true;
        }
      });

      document.querySelectorAll("[data-newmessage=true]").forEach((elm) => {
        try {
          if (elm.dataset.djangofield === "verb") {
            if (elm.value === msgData.verb) {
              elm.selected = true;
            }
          }

          if (elm.dataset.djangofield === "button_lbl" && elm !== undefined) {
            elm.value = msgData.button_lbl;
          }

          if (elm.dataset.djangofield === "verb_2") {
            if (elm.value === msgData.verb_2) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "optional") {
            if (elm.value === msgData.optional) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "status") {
            if (elm.value === msgData.status) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "status_2") {
            if (elm.value === msgData.status_2) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "restrictions") {
            if (elm.value === msgData.restrictions) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "restrictions_2") {
            if (elm.value === msgData.restrictions_2) {
              elm.selected = true;
            }
          }
          if (elm.value === msgData.competitor) {
            elm.selected = true;
          }
          if (elm.dataset.djangofield === "co_pay") {
            if (elm.value === msgData.co_pay) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "co_pay_2") {
            if (elm.value === msgData.co_pay_2) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "tier") {
            if (elm.value === msgData.tier) {
              elm.selected = true;
            }
          }
          if (elm.dataset.djangofield === "tier_2") {
            if (elm.value === msgData.tier_2) {
              elm.selected = true;
            }
          }

          if (elm.dataset.djangofield === "plan_1" && elm !== undefined) {
            elm.value = msgData.plan_1;
          }
          if (elm.dataset.djangofield === "plan_2") {
            elm.value = msgData.plan_2;
          }
          if (elm.dataset.djangofield === "plan_3") {
            elm.value = msgData.plan_3;
          }
          if (elm.dataset.djangofield === "plan_4") {
            elm.value = msgData.plan_4;
          }
          if (elm.dataset.djangofield === "effective_date") {
            elm.value = msgData.effective_date;
          }
        } catch (error) {
          console.log(error);
        }
      });

      // set the dates
      document.querySelector("#go-live-date").value = msgData.golive_date;
      document.querySelector("#expiration-date").value =
        msgData.expiration_date;

      // set permission type
      document.querySelectorAll("[data-type=permission]").forEach((elm) => {
        if (elm.name === msgData.permission) {
          elm.checked = true;
        }
      });

      // set deployment type : state/district/cbsa
      document.querySelectorAll("[data-type=deployment]").forEach((elm) => {
        if (elm.name === msgData.deployment) {
          elm.checked = true;

          let selectedDeploymentdata = {};
          let detailHtml = "";
          let targetelSelector = ".State-selections";

          if (msgData.deployment === "State") {
            targetelSelector = ".State-selections";
            selectedDeploymentdata = msgData.state;

            let ct = 0;
            for (let key in selectedDeploymentdata) {
              if (selectedDeploymentdata.hasOwnProperty(key)) {
                detailHtml = `${detailHtml} ${selectedDeploymentdata[key].state}<br/>`;
                ct++;
              }
            }
            document.querySelector(targetelSelector).innerHTML =
              ct >= 3 ? "Multiple states selected" : detailHtml;
          }

          if (msgData.deployment === "District") {
            targetelSelector = ".District-selections";
            selectedDeploymentdata = msgData.district;

            let ct = 0;
            for (let key in selectedDeploymentdata) {
              if (selectedDeploymentdata.hasOwnProperty(key)) {
                detailHtml = `${detailHtml} ${selectedDeploymentdata[key].district}<br/>`;
                ct++;
              }
            }
            document.querySelector(targetelSelector).innerHTML =
              ct >= 3 ? "Multiple districts selected" : detailHtml;
          }

          if (msgData.deployment === "City/CBSA") {
            targetelSelector = ".cbsa-selections";
            selectedDeploymentdata = msgData.cbsa;
            let ct = 0;
            for (let key in selectedDeploymentdata) {
              if (selectedDeploymentdata.hasOwnProperty(key)) {
                ct++;
                detailHtml = `${detailHtml} ${selectedDeploymentdata[key].cbsa}<br/>`;
              }
            }
            document.querySelector(targetelSelector).innerHTML =
              ct >= 3 ? "Multiple cities selected" : detailHtml;
          }
          // setConstructingPreview(true);
        }
      });

      setTemplateRendering("");
      // @todo: after set true, input field set blank
      setConstructingPreview(true);
    }, 2500);
  };

  const clearInputs = () => {
    // clear all radios btns
    document.querySelectorAll("[data-newmessageradio=true]").forEach((elm) => {
      elm.checked = false;
    });
    // clear all options
    document.querySelectorAll("option[data-newmessage=true]").forEach((elm) => {
      elm.selected = false;
    });
    // clear Deployment options that are
    // shown after the users selects options from the modal
    document.querySelectorAll(".deployment-selections").forEach((elm) => {
      elm.innerHTML = "";
    });
    document.querySelectorAll(".go-live__input>input").forEach((elm) => {
      elm.value = "";
    });
    // remove error states
    document.querySelector(".type__inputs").classList.remove("input--error");
    document
      .querySelector(".deployment__options")
      .classList.remove("input--error");
    document
      .querySelector(".permission__content")
      .classList.remove("input--error");

    // reset booleans that display content as options are selected
    setTemplateType("");
    setTemplateTypeSelected(false);
    handlePreviewChangeEvent();
  };

  // New Message Submit
  // Opens Modal window
  const handleNewMessageSubmit = (event) => {
    event.preventDefault();

    let missingRequiredField = checkRequiredInputs();
    if (missingRequiredField) {
      // show error
      setNotification("A required form field is missing.");
      setNotificationClass("notification--show");
      // reset after timeout otherwise the change
      // happens too fast and the modal will
      // not work again
      setTimeout(resetNotifications, 5500);
      return;
    }

    let missingDeploymentOptions = checkSelectedDeploymentOptions();
    if (missingDeploymentOptions) {
      // show error
      setNotification(
        "You must SELECT your State, District, or City deployment options."
      );
      setNotificationClass("notification--show");
      // reset after timeout otherwise the change
      // happens too fast and the modal will
      // not work again
      setTimeout(resetNotifications, 5500);
      return;
    }

    let datesMessage = checkDateDeltas();
    if (datesMessage.error === true) {
      // show error
      setNotification(datesMessage.message);
      setNotificationClass("notification--show");
      // reset after timeout otherwise the change
      // happens too fast and the modal will
      // not work again
      setTimeout(resetNotifications, 5500);
      return;
    }

    setModalClassName(""); // show modal
    setModalHeaderText("New Message Submission Confirmation");
    setModalBodyTextTop("");
    setModalBodyText(
      "If you are satisfied with the preview, please click the 'Submit' button to confirm this new message"
    );
    setModalButtonOneText("Submit");
    setModalButtonOneClassName("");
    setModalButtonOneOnClick(() => handleConfirmSubmit);
    setModalButtonTwoText("Edit");
    setModalButtonTwoOnClick(() => handleModalCancel);
    setModalChildren("previewMessage");

    // scrollto top of new message and disable overflow
    document.querySelector("#create-new-message").scrollTo(0, 0);
    document
      .querySelector("#create-new-message")
      .classList.add("msg--disable-scroll");
  };

  // New Message Cancel
  // Resets everything and closes NewMessage
  const handleNewMessageCancel = () => {
    const performCancel = () => {
      window.open("/", "_self");
    };
    setModalClassName("");
    setModalHeaderText("Cancel New Message?");
    setModalBodyTextTop(
      "By clicking 'Cancel', your changes will be lost forever. Are you sure you want to cancel this message?"
    );
    setModalBodyText();
    setModalButtonOneText("Cancel");
    setModalButtonOneType("button");
    setModalButtonOneClassName("");
    setModalButtonOneOnClick(() => performCancel);
    setModalButtonTwoText("Edit");
    setModalButtonTwoType("Button");
    setModalButtonTwoClassName("");
    setModalButtonTwoOnClick(() => handleModalCancel);
    setModalChildren("deleteMessage");
  };
  const modalChildrenEditContent = {
    previewMessage: <MessagePreview str={messagePreview} />,
    districtDeployments: (
      <DistrictOptions selectedDistrict={msgData.district}  mode={'edit'}  selectedProduct ={msgData.parent_product}/>
    ),
    cbsaDeployments: <CityCbsaOptions selectedCity={msgData.cbsa}  mode={'edit'}/>,
    stateDeployments: (
      <StatesOptions states={states} selectedState={msgData.state} />
    ),

  };
  const modalChildrenContent = {
    previewMessage: <MessagePreview str={messagePreview} />,
    districtDeployments: <DistrictOptions selectedDistrict={{}}  mode={'create'} selectedProduct ={selectedProduct}/>,
    cbsaDeployments: <CityCbsaOptions selectedCity={{}} mode={'create'}/>,
    stateDeployments: <StatesOptions states={states} selectedState={{}} />,
  };

  const currentModalChildren = editMode
    ? modalChildrenEditContent[modalChildren] || null
    : modalChildrenContent[modalChildren] || null;

  return (
    <Template>
      <section id="create-new-message" className="msg">
        <div className="msg__content">
          <form className="msg__form">
            <div className="msg__generation-preview">
              <section className="msg__generation">
                {templateRendering === "d-none" ? (
                  <LabeledLoader label="Loading ..." />
                ) : (
                  ""
                )}
                <Type
                  setTemplateType={setTemplateType}
                  setTemplateTypeSelected={setTemplateTypeSelected}
                />
                {templateTypeSelected ? (
                  <MessageTemplateInputs
                    visible={templateRendering}
                    templateType={templateType}
                    clearInputs={clearInputs}
                    productSelected={productSelected}
                    setProductSelected={setProductSelected}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    constructingPreview={constructingPreview}
                    setConstructingPreview={setConstructingPreview}
                    handleStaticFieldChangeEvent ={handleStaticFieldChangeEvent}
                    handlePreviewChangeEvent={handlePreviewChangeEvent}
                    setModalClassName={setModalClassName}
                    setModalHeaderText={setModalHeaderText}
                    setModalBodyText={setModalBodyText}
                    setModalBodyTextTop={setModalBodyTextTop}
                    setModalButtonOneText={setModalButtonOneText}
                    setModalButtonOneType={setModalButtonOneType}
                    setModalButtonOneClassName={setModalButtonOneClassName}
                    setModalButtonOneOnClick={setModalButtonOneOnClick}
                    setModalButtonTwoText={setModalButtonTwoText}
                    setModalButtonTwoType={setModalButtonTwoType}
                    setModalButtonTwoClassName={setModalButtonTwoClassName}
                    setModalButtonTwoOnClick={setModalButtonTwoOnClick}
                    setModalChildren={setModalChildren}
                    handleErrorEvent={sethandleErrorEvent}
                  />
                ) : (
                  <div>Waiting for selection...</div>
                )}
              </section>
              <Preview
                messagePreview={messagePreview}
                setMessagePreview={setMessagePreview}
                constructingPreview={constructingPreview}
                setConstructingPreview={setConstructingPreview}
              />
            </div>

            <DeploymentTypes
              loadingStates={loadingStates}
              loading={loadingDeployments}
              options={deploymentOptions}
              setModalClassName={setModalClassName}
              setModalHeaderText={setModalHeaderText}
              setModalBodyText={setModalBodyText}
              setModalBodyTextTop={setModalBodyTextTop}
              setModalButtonOneText={setModalButtonOneText}
              setModalButtonOneType={setModalButtonOneType}
              setModalButtonOneClassName={setModalButtonOneClassName}
              setModalButtonOneOnClick={setModalButtonOneOnClick}
              setModalButtonTwoText={setModalButtonTwoText}
              setModalButtonTwoType={setModalButtonTwoType}
              setModalButtonTwoClassName={setModalButtonTwoClassName}
              setModalButtonTwoOnClick={setModalButtonTwoOnClick}
              setModalChildren={setModalChildren}
              setNotification={setNotification}
              setNotificationClass={setNotificationClass}
            />

            <Permission />

            <GoLive />

            <section className="msg__buttons">
              <Button
                type="button"
                text="Submit"
                onClick={handleNewMessageSubmit}
              />
              <Button
                id="cancel-new-message"
                type="button"
                text="Cancel"
                className="button__stripped"
                onClick={handleNewMessageCancel}
              />
            </section>
            <Text className="msg__form-required">
              *Required fields are indicated with an asterisk.
            </Text>
          </form>
        </div>
      </section>

      <Modal
        headerText={modalHeaderText}
        bodyTextTop={modalbodyTextTop}
        bodyText={modalbodyText}
        className={modalClassName}
        buttonOne={[
          modalButtonOneText,
          modalButtonOneType,
          modalButtonOneClassName,
          modalButtonOneOnClick,
        ]}
        buttonTwo={[
          modalButtonTwoText,
          modalButtonTwoType,
          modalButtonTwoClassName,
          modalButtonTwoOnClick,
        ]}
      >
        {currentModalChildren}
      </Modal>

      <NotificationSlider
        type="slider"
        children={notification}
        className={notificationClass}
      />

      <Footer />
    </Template>
  );
}
