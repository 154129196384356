import { useState, useEffect } from "react";
import "./style.scss";
import Template from "../Template";
// components
import Filters from "./components/Filters";
import Sorts from "./components/Sorts";
import Messages from "./components/Messages";
import { DeleteMessage } from "./components/ModalChildren";
import Modal from "../../components/Modal";
import { NotificationSlider } from "../../components/Notification";
import Footer from "../../components/Footer";
import LabeledLoader from "../../components/LabeledLoader";
// utils
import { getData } from "../../utils/requests";
import { filterMessages } from "./components/Filters/libs/filterfuncs";

export default function Dashboard() {
  const [notification, setNotification] = useState("");
  const [notificationClass, setNotificationClass] = useState("d-none");
  const resetNotifications = () => {
    // reset notification to blank
    setNotification("");
    setNotificationClass("");
  };
  const authUserId = window.localStorage.getItem("uid");
  // loading Messages
  const [loadingMessages, setLoadingMessages] = useState(true); // bool: when true, we're fetching new messages
  // loading users
  const [loadingUsers, setLoadingUsers] = useState(true); // bool: when true, we're fetching the list of users
  const [users, setUsers] = useState([]);
  const [userApiUrl, setUserApiUrl] = useState(
    `${process.env.REACT_APP_API_HOST}/api/user/`
  );
  // Message Sort options
  const [messageSortType, setMessageSortType] = useState("None");
  const [messageSortOption, setMessageSortOption] = useState("None");
  const [messageSortDirection, setMessageSortDirection] = useState("None");

  // States
  const [states, setStates] = useState([]); // array of states
  const [loadingStates, setLoadingStates] = useState(true); // booL: true when laoding states
  const [statesApiURL, setStatesApiURL] = useState(
    `${process.env.REACT_APP_API_HOST}/api/states/`
  );

  // Modal sates
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalbodyText, setModalBodyText] = useState("");
  const [modalbodyTextTop, setModalBodyTextTop] = useState("");
  const [modalClassName, setModalClassName] = useState("d-none");
  // first button
  const [modalButtonOneText, setModalButtonOneText] = useState("");
  const [modalButtonOneType, setModalButtonOneType] = useState("");
  const [modalButtonOneClassName, setModalButtonOneClassName] = useState("");
  const [modalButtonOneOnClick, setModalButtonOneOnClick] = useState();
  // second button
  const [modalButtonTwoText, setModalButtonTwoText] = useState("");
  const [modalButtonTwoType, setModalButtonTwoType] = useState("");
  const [modalButtonTwoClassName, setModalButtonTwoClassName] = useState("");
  const [modalButtonTwoOnClick, setModalButtonTwoOnClick] = useState();
  // modal children
  // Using this as a string that will be set as an object Key
  // The currect actie key will be displayed in the modal window
  const [modalChildren, setModalChildren] = useState();

  useEffect(() => {
    getData(userApiUrl, setUserApiUrl, setLoadingUsers, setUsers);
  }, [userApiUrl]);

  useEffect(() => {
    getData(statesApiURL, setStatesApiURL, setLoadingStates, setStates);
  }, [statesApiURL]);

  const showNewMessage = () => {
    window.open("/message/", "_self");
  };

  const modalChildrenContent = {
    deleteMessage: <DeleteMessage />,
  };

  const currentModalChildren = modalChildrenContent[modalChildren] || null;

  //init user filter - on page load, user will see only their message
  if (!loadingMessages) {
    let tartet_filter = document.querySelector("[data-target='#user-filter']");
    setTimeout(function () {
      if (tartet_filter.textContent !== "All ^") {
        filterMessages("", "", "", authUserId);
      }
    }, 500);
  }

  return (
    <Template>
      <section className="dashboard container">
        {loadingStates ? (
          <LabeledLoader label="Loading states..." />
        ) : (
          <Filters
            states={states}
            users={users}
            loadingUsers={loadingUsers}
            setLoadingUsers={setLoadingUsers}
            loadingMessages={loadingMessages}
          />
        )}

        <Sorts
          setMessageSortType={setMessageSortType}
          setMessageSortOption={setMessageSortOption}
          setMessageSortDirection={setMessageSortDirection}
        />

        <Messages
          users={users}
          loadingMessages={loadingMessages}
          setLoadingMessages={setLoadingMessages}
          messageSortType={messageSortType}
          messageSortOption={messageSortOption}
          messageSortDirection={messageSortDirection}
          setModalClassName={setModalClassName}
          setModalHeaderText={setModalHeaderText}
          setModalBodyText={setModalBodyText}
          setModalBodyTextTop={setModalBodyTextTop}
          setModalButtonOneText={setModalButtonOneText}
          setModalButtonOneType={setModalButtonOneType}
          setModalButtonOneClassName={setModalButtonOneClassName}
          setModalButtonOneOnClick={setModalButtonOneOnClick}
          setModalButtonTwoText={setModalButtonTwoText}
          setModalButtonTwoType={setModalButtonTwoType}
          setModalButtonTwoClassName={setModalButtonTwoClassName}
          setModalButtonTwoOnClick={setModalButtonTwoOnClick}
          setModalChildren={setModalChildren}
          setNotification={setNotification}
          setNotificationClass={setNotificationClass}
          resetNotifications={resetNotifications}
        />
      </section>

      <Modal
        headerText={modalHeaderText}
        bodyTextTop={modalbodyTextTop}
        bodyText={modalbodyText}
        className={modalClassName}
        buttonOne={[
          modalButtonOneText,
          modalButtonOneType,
          modalButtonOneClassName,
          modalButtonOneOnClick,
        ]}
        buttonTwo={[
          modalButtonTwoText,
          modalButtonTwoType,
          modalButtonTwoClassName,
          modalButtonTwoOnClick,
        ]}
      >
        {currentModalChildren}
      </Modal>

      <NotificationSlider
        type="slider"
        children={notification}
        className={notificationClass}
      />

      <Footer showNewMessageEvent={showNewMessage} />
    </Template>
  );
}
