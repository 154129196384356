import { Label } from "../UILib";

export default function GoLive() {
  return (
    <section className="go-live">
      <div className="go-live__content">
        <div className="go-live__input">
          <Label type="l2" align="left" htmlFor="go-live-date">
            Enter Go Live Date:
          </Label>
          <input
            id="go-live-date"
            name="go-live-date"
            type="date"
            data-type="date"
          />
        </div>
        <div className="go-live__input">
          <Label type="l2" align="left" htmlFor="expiration-date">
            Enter Expiration Date:
          </Label>
          <input
            id="expiration-date"
            name="expiration-date"
            type="date"
            data-type="date"
          />
        </div>
      </div>
    </section>
  );
}
