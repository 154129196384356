// form validations
export const validEmail = (email) => {
  if (email === "Email" || email === "") {
    return -1;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = email.search(re);
  return isValid;
};

export const validNumber = (code) => {
  if (code === null || code === undefined) {
    return -1;
  }
  const re = /^([0-9]{6})$/;
  const isValid = code.search(re);
  return isValid;
};
