import { useState, useEffect } from "react";
import { string } from "prop-types";
import parse from "html-react-parser";

import LabeledLoader from "../../../../components/LabeledLoader";
import { getData } from "../../../../utils/requests";
import DistrictFilter from "./component/DistrictFilter";
import CityFilter from "./component/CityFilter";
import StateFilter from "./component/StateFilter";


export const MessagePreview = ({ str }) => {
  return <div className="msg__modal-preview--center">{parse(str)}</div>;
};

MessagePreview.propTypes = {
  previewText: string,
};

export const StatesOptions = ({ states, selectedState }) => {
  //selected states
  selectedState = undefined ? {} : selectedState;
  const [hideRow, setHideRow] = useState([])
  const [filteredCity, setFilteredCity] = useState([])
  const [SelectedStatesInfo, setSelectedStatesInfo] = useState(selectedState);
  const [filteredState, setFilteredState] = useState([])
  const stateOptionHandler = (item) => {
    let checked = false;
    let changes = null;

    if (
      document
        .querySelector(".State-selections")
        .getAttribute("data-deployment-state-selected") !== null
    ) {
      changes = JSON.parse(
        document
          .querySelector(".State-selections")
          .getAttribute("data-deployment-state-selected")
      );
      selectedState = changes;
    }

    try {
      if (selectedState !== null || selectedState !== undefined)
        checked = selectedState[item.id] ? true : false;
    } catch (e) {}

    return checked;
  };

  const statetoggleHandler = (item) => () => {};
  const onStateFilterChange = (filteredState) =>{
    console.log('filter changed')
    //console.log(filteredState) //selected
    let filtered = states.filter( (el, index)=>{
      let targetEl = document.querySelector(`.modal__checkbox-row.row-${el.id}`)
            if(filteredState.includes(el)){
                //targetEl.style.display ='none';
                //console.log(`hide ${el.district_description}`)
            }else{
             // console.log(`show ${el.district_description}`)
            }
            return !filteredState.includes(el)
        })


       /* filteredDistrict.forEach((item, index) =>{
          //console.log(item)
          //console.log(item.district_description)
           let targetEl = document.querySelector(`.modal__checkbox-row.row-${item.id}`)
              //  targetEl.style.display ='none';
        })*/

  }

  return (
    <div className="modal__options-scroller">
      <StateFilter
              name="statefilter"
              data={states}
              selectedState={SelectedStatesInfo}
              setHideRow={setHideRow}
              setFilteredState={setFilteredState}
              onFilterChange={onStateFilterChange}
          />
      {states.map((item) => {
        return (
          <div
            key={`deploymentOptionId-${item.id}-type-${item.abbreviations}-name-${item.name}`}
            className="modal__checkbox-row"
          >
            <input
              type="checkbox"
              id={`checkbox-${item.id}-${item.abbreviation}`}
              name={`checkbox-${item.id}-${item.abbreviation}`}
              data-deployment-option-sid={item.id}
              data-deployment-option-value={item.name}
              data-deployment-option-type="state"
              data-deployment-option-abbr={item.abbreviation}
              data-deployment-option-states={item.id}
              data-deployment-option-itemid={item.id}
              data-deployment-option-state={JSON.stringify(SelectedStatesInfo)}
              data-type="deploymentOption"
              defaultChecked={stateOptionHandler(item)}
              onChange={statetoggleHandler(item)}
            />
            <label
              htmlFor={`checkbox-${item.id}-${item.abbreviation}`}
              name={`checkbox-${item.id}-${item.abbreviation}`}
              data-deployment-option-value={item.name}
              data-deployment-option-type={item.type}
              data-deployment-option-states={item.id}
            >
              {item.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const DistrictOptions = ({ selectedDistrict, mode, selectedProduct }) => {
  const [districts, setDistricts] = useState([]); // array of districts
  const [loadingDistricts, setLoadingDistricts] = useState(true); // booL: true when laoding districts
  const [districtsApiURL, setDistrictsApiURL] = useState(
    `${process.env.REACT_APP_API_HOST}/api/districts/?product_name=${selectedProduct}`
  );
  const [SelectedDistricsInfo, setSelectedDistricInfo] =
    useState(selectedDistrict);

  useEffect(() => {
    // get District Data
    getData(
      districtsApiURL,
      setDistrictsApiURL,
      setLoadingDistricts,
      setDistricts
    );
  }, [districtsApiURL]);

  const [hideRow, setHideRow] = useState([])
  const [filteredDistrict, setFilteredDistrict] = useState([])
  const districtOptionHandler = (item) => {
    let checked = false;
    let changes = null;
    //if there is any changes
    if (
      document
        .querySelector(".District-selections")
        .getAttribute("data-deployment-districts-selected") !== null
    ) {
      changes = JSON.parse(
        document
          .querySelector(".District-selections")
          .getAttribute("data-deployment-districts-selected")
      );
      selectedDistrict = changes;
    }

    try {
      if (SelectedDistricsInfo !== null || SelectedDistricsInfo !== undefined)
        checked = SelectedDistricsInfo[item.id] ? true : false;
       if(checked){
          document.querySelector(`modal__checkbox-row row-${item.id}`).classList.add('checked')
         console.log(`modal__checkbox-row row-${item.id}`)
        }



    } catch (e) {}


    return checked;
  };


  const[displayFiltered, setDisplayFiltered] = useState()

  const districttoggleHandler = (item) => () => {};
  const onFilterChange = (filteredDistrict) =>{
    console.log('filter changed')
    //console.log(filteredDistrict) //selected
    let filtered = districts.filter( (el, index)=>{
      let targetEl = document.querySelector(`.modal__checkbox-row.row-${el.id}`)
            if(filteredDistrict.includes(el)){
                //targetEl.style.display ='none';
                //console.log(`hide ${el.district_description}`)
            }else{
             // console.log(`show ${el.district_description}`)
            }
            return !filteredDistrict.includes(el)
        })


       /* filteredDistrict.forEach((item, index) =>{
          //console.log(item)
          //console.log(item.district_description)
           let targetEl = document.querySelector(`.modal__checkbox-row.row-${item.id}`)
              //  targetEl.style.display ='none';
        })*/

  }
  return (
    <>

      {loadingDistricts ? (
        <LabeledLoader label="Loading districts..." />
      ) : (


        <div className="modal__options-scroller">
          <DistrictFilter
              name="districtfilter"
              data={districts}
              selectedDistrict={SelectedDistricsInfo}
              setHideRow={setHideRow}
              setFilteredDistrict={setFilteredDistrict}
              onFilterChange={onFilterChange}
              mode={mode}
          />
          {districts.map((item) => {
            return (
              <div
                key={`deploymentOptionId-${item.id}-district-${item.district}`}
                className={`modal__checkbox-row row-${item.id} show`}
              >
                <input
                  type="checkbox"
                  id={`checkbox-${item.id}-${item.district}`}
                  name={`checkbox-district-${item.id}-${item.district}`}
                  data-deployment-option-value={item.district_description}
                  data-deployment-option-type="district"
                  data-deployment-option-itemid={item.id}
                  data-deployment-option-state={JSON.stringify(
                    SelectedDistricsInfo
                  )}
                  data-type="deploymentOption"
                  onChange={districttoggleHandler(item)}
                  defaultChecked={districtOptionHandler(item)}
                />
                <label
                  htmlFor={`checkbox-${item.id}-${item.district}`}
                  name={`checkbox-${item.id}-${item.district}`}
                  data-deployment-option-value={item.name}
                  data-deployment-option-type={item.district}
                >
                  {/* {`${item.district} ${item.district_description}`} */}
                  {`${item.district_description} - [ ${item.district_team} ]`}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export const CityCbsaOptions = ({ selectedCity , mode }) => {
  const [hideRow, setHideRow] = useState([])
  const [filteredCity, setFilteredCity] = useState([])
  const [cbsa, setCbsa] = useState([]); // array of CBSA's
  const [loadingCbsa, setLoadingCbsa] = useState(true); // booL: true when laoding states
  const [cbsaApiURL, setCbsaApiURL] = useState(
    `${process.env.REACT_APP_API_HOST}/api/cbsa/`
  );
  const [SelectedCityInfo, setSelectedCityInfo] = useState(selectedCity);

  useEffect(() => {
    // get cbsa Data
    getData(cbsaApiURL, setCbsaApiURL, setLoadingCbsa, setCbsa);
  }, [cbsaApiURL]);

  const citytoggleHandler = (item) => () => {};

    const onCityFilterChange = (filteredCity) => {
       console.log('filter city changed')
    let filtered = cbsa.filter( (el, index)=>{
      let targetEl = document.querySelector(`.modal__checkbox-row.row-${el.id}`)
            if(filteredCity.includes(el)){
                //targetEl.style.display ='none';
                //console.log(`hide ${el.district_description}`)
            }else{
             // console.log(`show ${el.district_description}`)
            }
            return !filteredCity.includes(el)
        })

    }

  const cityOptionHandler = (item) => {
    let checked = false;
    let changes = null;
    //if there is any changes
    if (
      document
        .querySelector(".cbsa-selections")
        .getAttribute("data-deployment-cbsa-selected") !== null
    ) {
      changes = JSON.parse(
        document
          .querySelector(".cbsa-selections")
          .getAttribute("data-deployment-cbsa-selected")
      );
      selectedCity = changes;
    }
    try {
      if (selectedCity !== null || selectedCity !== undefined)
        checked = selectedCity[item.id] ? true : false;
    } catch (e) {}

    return checked;
  };

  return (
      <>

      {loadingCbsa ? (
        <LabeledLoader label="Loading cities..." />
      ) : (

        <div className="modal__options-scroller">
          <CityFilter
                        name="districtfilter"
                        data={cbsa}
                        selectedCity={SelectedCityInfo}
                        setHideRow ={setHideRow}
                        setFilteredCity ={setFilteredCity}
                        onCityFilterChange = {onCityFilterChange}
                        mode ={mode}
                      />
          {cbsa.map((item) => {
            return (
              <div
                key={`deploymentOptionId-${item.id}-cbsa-${item.cbsa}`}
                  className={`modal__checkbox-row row-${item.id} show`}
              >
                <input
                  type="checkbox"
                  id={`checkbox-${item.id}-${item.cbsa}`}
                  name={`checkbox-cbsa-${item.id}-${item.cbsa}`}
                  data-deployment-option-value={item.cbsa_name}
                  data-deployment-option-type="cbsa"
                  data-deployment-option-itemid={item.id}
                  data-type="deploymentOption"
                  data-deployment-option-state={JSON.stringify(
                    SelectedCityInfo
                  )}
                  onChange={citytoggleHandler(item)}
                  defaultChecked={cityOptionHandler(item)}
                />
                <label
                  htmlFor={`checkbox-${item.id}-${item.cbsa}`}
                  name={`checkbox-${item.id}-${item.cbsa}`}
                  data-deployment-option-value={item.cbsa}
                  data-deployment-option-type={item.cbsa}
                >
                  {/* {`${item.cbsa} ${item.cbsa_name}`} */}
                  {`${item.cbsa_name}`}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
