import classNames from "classnames";
import "./style.scss";

export default function Notification({ header, text, className }) {
  return (
    <section className={`notification ${className}`}>
      <div className="notification__content">
        <h3 className="notification__h3">{header}</h3>
        <p className="notification__p">{text}</p>
      </div>
    </section>
  );
}

export const NotificationSlider = ({ type, className, children }) => {
  const NOTIFICATION_CSS = classNames({
    "notification-slider": type === "slider",
    [className]: className,
  });
  return (
    <section className={NOTIFICATION_CSS}>
      <p>{children}</p>
    </section>
  );
};
