import { string } from "prop-types";
import "./style.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default function index({ label }) {
  return (
    <div className="customloader">
      <Loader type="Oval" color="#174578" height={32} width={32} />
      <span>{label}</span>
    </div>
  );
}

index.propTyes = {
  label: string,
};
