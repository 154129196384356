import { Text } from "../../../components/UILib";
import Button from "../../../components/Button";
import parse from "html-react-parser";
import errorIndicatorImgLarge from "../../Dashboard/components/Message/error-indicator-large.png";
import errorIndicatorImg from "../../Dashboard/components/Message/error-indicator.png";
import { useState } from "react";
import {
  formatDateInputData,
  formatDateServer,
  isDateExpired,
} from "../../../utils";

export const NationalMsgListItem = ({
  data,
  isMain,
  owner,
  filtered,
  selectedBrand,
}) => {
  const handleEdit = () => {
    // open the /message/<id>/ page to edit
    window.open(`/message/?id=${data.id}&edit=true`, "_self");
  };
  return (
    <div
      className={`nationMsg__list_item ${
        isDateExpired(data.expiration_date) && "expired"
      }`}
      data-msg-brand-id={data.proudct_brand_id}
    >
      <div className={`list-item__info message_id ${isMain}`}>
        Message ID: {data.id}
      </div>
      <div className="message__content">
        <div className="message__body">
          {(data.approval_status === 4 || data.approval_status === 5) && (
            <div className="message__warning">
              <img alt="warning icon" src={errorIndicatorImgLarge} />
            </div>
          )}
          <div className="message__body_message">{parse(data.message)}</div>
          <div className="message__options">
            {(data.approval_status === 1 || data.approval_status === 4) && (
              <Button
                text="Edit"
                className="message__button"
                onClick={handleEdit}
              />
            )}
            {data.approval_status === 3 && (
              <Button
                text="Edit"
                className="message__button"
                onClick={handleEdit}
                disabled={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className="message__metta">
        <strong>
          {data.deployment} | {data.priority} | Created on{" "}
          {formatDateInputData(data.created_at)} |{" "}
          <span
            className={`${isDateExpired(data.expiration_date) && "warning"}`}
          >
            {isDateExpired(data.expiration_date) && <span>Expired </span>}
            {!isDateExpired(data.expiration_date) && <span>Expires </span>}
            {formatDateInputData(data.expiration_date)}{" "}
          </span>
          | Goes Live on {formatDateInputData(data.golive_date)}
        </strong>
      </div>
      {owner !== undefined && (
        <div className="message__metta">
          Message Owner: {owner.first_name} {owner.last_name}
        </div>
      )}
      {(data.approval_status === 1 || data.approval_status === 5) && (
        <div className="message__metta message--warning">
          <img alt="warning icon" src={errorIndicatorImg} />
          <Text type="p1">This message is pending approval.</Text>
        </div>
      )}
      {data.approval_status === 4 && (
        <div className="message__metta message--warning">
          <Text type="p1">
            This message has been rejected. Please review this message and make
            any required edits. If this message is not reviewed in 30 days, it
            will automatically be deleted.
          </Text>
        </div>
      )}
      {data.approval_status === 5 && (
        <div className="message__metta message--warning">
          <Text type="p1">This message has been rejected and updated.</Text>
        </div>
      )}
    </div>
  );
};
