import { array, string } from "prop-types";
import { reactKeyGenerator } from "../../../../utils";

export default function Filter({
  name, // string: the name of the filter type
  data, // array: the data for the filter
}) {
  const handleOptionClick = (event) => {
    // select the <option> that correlates to the custom options
    document.querySelector(
      `[data-target="${name}-filters"] option[data-value="${event.target.dataset.value}"]`
    ).selected = true;

    // hide the drop custom options
    document.querySelector(`#${name}-filters`).classList.add("d-none");

    filterMessages(event);
  };

  const filterMessages = (event) => {
    // get the active filter
    const activeFilter = event.target.dataset.value;
    // get all messages that were loaded
    document.querySelectorAll("[data-type=message").forEach((item) => {
      // display all
      if (activeFilter === "None" || activeFilter === "All") {
        item.classList.remove("d-none");
        return;
      }

      // Filter out users
      if (event.target.dataset.filtertype === "User") {
        document.querySelectorAll("[data-type=message").forEach((item) => {
          // hide messages that don't match the filter
          if (item.dataset.filterOwner !== event.target.dataset.id) {
            item.classList.add("d-none");
          } else {
            item.classList.remove("d-none");
          }
        });
      } else if (event.target.dataset.filtertype === "Product") {
        //console.log("product");
      } else {
        // hide messages that don't match the filter
        if (item.dataset[`filter${name}`] !== activeFilter) {
          item.classList.add("d-none");
        } else {
          item.classList.remove("d-none");
        }
      }
    });
  };

  return (
    <>
      <label htmlFor={`${name}-filter`} className="filters__label">
        <strong>{name}:</strong>
      </label>
      <section className="filters__group">
        <div id={`${name}-filters`} className="filters__custom-options d-none">
          <div
            className="filters__custom-option"
            data-value="All"
            onClick={handleOptionClick}
          >
            All
          </div>
          {data.map((item) => {
            const nameIsUser = name === "User";
            return (
              <div
                className="filters__custom-option"
                data-value={
                  nameIsUser
                    ? `${item.first_name}_${item.last_name}`
                    : item.name
                }
                data-filtertype={name}
                data-id={item.id}
                key={`customOption-${name}-id-${
                  item.id
                }-randomKey-${reactKeyGenerator()}`}
                onClick={handleOptionClick}
              >
                {nameIsUser ? `${item.email}` : item.name}
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

Filter.propTypes = {
  name: string,
  data: array,
};
