import React, { useState, useEffect } from "react";
import { func } from "prop-types";
import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import "./style.scss";
import Sort from "./Sort";

export default function Sorts({
  setMessageSortType,
  setMessageSortDirection,
  setMessageSortOption,
}) {
  // const [sortOptions, setSortOptions] = useState([]);
  const [priority, setPriority] = useState([]);
  const [dates, setDates] = useState([]);
  const [direction, setDirection] = useState([]);
  const [loading, setLoading] = useState(true);

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
  };

  const fetchSortTypes = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_HOST}/api/message-sort/`, headers)
        .then((res) => {
          const data = res.data.results;
          setPriority(data.filter((sort) => sort.type === "priority"));
          setDates(data.filter((sort) => sort.type === "date"));
          setDirection(data.filter((sort) => sort.type === "direction"));
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoadingDeployments(true)
    }
  };

  useEffect(() => {
    fetchSortTypes();
  }, []);

  return (
    <section className="sorts">
      <p className="sorts__p">
        <strong>Sort by:</strong>
      </p>
      {loading ? (
        <Loader type="Oval" color="#174578" height={32} width={32} />
      ) : (
        <Sort
          type="Priority"
          data={priority}
          setMessageSortType={setMessageSortType}
          setMessageSortOption={setMessageSortOption}
          setMessageSortDirection={setMessageSortDirection}
        />
      )}
      {loading ? (
        <Loader type="Oval" color="#174578" height={32} width={32} />
      ) : (
        <Sort
          type="Date"
          data={dates}
          setMessageSortType={setMessageSortType}
          setMessageSortOption={setMessageSortOption}
          setMessageSortDirection={setMessageSortDirection}
        />
      )}
      {loading ? (
        <Loader type="Oval" color="#174578" height={32} width={32} />
      ) : (
        <Sort
          type="Direction"
          data={direction}
          setMessageSortType={setMessageSortType}
          setMessageSortOption={setMessageSortOption}
          setMessageSortDirection={setMessageSortDirection}
        />
      )}
    </section>
  );
}

Sorts.propTypes = {
  setMessageSortType: func,
  setMessageSortOption: func,
  setMessageSortDirection: func,
};
