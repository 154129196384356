import { useState } from "react";
import axios from "axios";

import "./style.scss";
import logo from "./amp_logo.png";

import Button from "../../components/Button";
import Notification from "../../components/Notification";
import { validEmail, validNumber } from "../../utils/regex";

export default function Login() {
  const [email, setEmail] = useState("");
  const [emailDisabled, setEmailDisabled] = useState(false); // enabled by default
  const [emailSectionClass, setEmailSectionClass] = useState(""); // visible by default
  const [code, setCode] = useState("");
  const [codeDisabled, setCodeDisabled] = useState(true); // disble the code by default
  const [codeSectionClass, setCodeSectionClass] = useState(
    // hidden by default
    "d-none"
  );
  const [notificationHeaderText, setNotificationHeaderText] = useState(""); // make empty by default
  const [notificationText, setNotificationText] = useState(""); // make empty by default
  const [notificationClass, setNotificationClass] = useState(
    // make hidden by default
    "d-none"
  );

  const handleChange = (event) => {
    // validate each form field onChange and
    // set the value of the input
    let isValid = -1;
    const inputValue = event.target.value;

    if (event.target.name === "email") {
      setEmail(event.target.value);
      isValid = validEmail(inputValue);
      //once new email is entered, will delete the localstorage data to make sure
      //back button will not work
      window.localStorage.clear();
    }
    if (event.target.name === "code") {
      setCode(inputValue);
      isValid = validNumber(inputValue);
    }

    // error state
    if (isValid === -1) {
      setNotificationClass("");
      if (event.target.name === "email") {
        setNotificationHeaderText("Invalid Email Address");
        setNotificationText("Please enter a valid email address.");
      }
      if (event.target.name === "code") {
        setNotificationHeaderText("Invalid Security Code");
        setNotificationText("Please enter a valid security code.");
      }
      return false;
    }
    // remove error is everything is OK
    setNotificationClass("d-none");
    setNotificationHeaderText("");
    setNotificationText("");

    return true;
  };

  const setActiveUser = (data) => {
    // set active user in window.localStorage
    // after the user has logged in successfully with a code.
    // delete old values
    window.localStorage.clear();
    window.localStorage.setItem("uid", data.uid);
    window.localStorage.setItem("lastLogin", Date());
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Api-Key ${process.env.REACT_APP_AMP_API_KEY}`,
    },
    cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.target.disabled = true; // disabled while verifying

    if (codeDisabled) {
      if (validEmail(email) !== -1) {
        axios
          .get(
            `${process.env.REACT_APP_API_HOST}/api/login/?email=${email}`,
            headers
          )
          .then((res) => {
            // response failed
            if (res.data.status === "failed") {
              //console.log(res.data);
              setNotificationHeaderText("Invalid Email Address");
              setNotificationText("Please enter a valid email address.");
              event.target.disabled = false;
              return;
            }
            // success
            // make code section visible, and disable email
            setEmailSectionClass("d-none");
            setEmailDisabled(true);
            setCodeSectionClass("");
            setCodeDisabled(false);
          })
          .catch((err) => {
            event.target.disabled = false;
            console.log(err);
          });
      } else {
        setNotificationHeaderText("Invalid Email Address");
        setNotificationText("Please enter a valid email address.");
      }
      event.target.disabled = false;
    }

    if (codeDisabled === false) {
      if (validNumber(code) !== -1) {
        // send login request

        axios
          .get(
            `${process.env.REACT_APP_API_HOST}/api/login/key-submit/?email=${email}&key=${code}`,
            headers
          )
          .then((res) => {
            const data = res.data;
            // response failed
            if (res.data.status === "failed") {
              //console.log(data);
              setNotificationHeaderText("Invalid Security Code");
              setNotificationText("Please enter a valid security code.");
              event.target.disabled = false;
              return;
            }
            setActiveUser(data);
            window.open("/", "_self");
          })
          .catch((err) => {
            setNotificationHeaderText("Invalid Security Code");
            setNotificationText("Please enter a valid security code.");
            console.log(err);
          });
        event.target.disabled = false;
      } else {
        setNotificationHeaderText("Invalid Security Code");
        setNotificationText("Please enter a valid security code.");
      }
    }
    event.target.disabled = false;
    return false;
  };

  return (
    <main className="login">
      <section className="login__content">
        <div className="login__logo-wrapper">
          <img className="login__logo" src={logo} alt="Amp Logo" />
        </div>

        <Notification
          className={notificationClass}
          header={notificationHeaderText}
          text={notificationText}
        />

        <form className="login__form" onSubmit={handleSubmit}>
          <div className={`login__form-group ${emailSectionClass}`}>
            <label className="login__label" htmlFor="email">
              Email:
            </label>
            <input
              className="login__input"
              name="email"
              typeof="email"
              id="email"
              value={email}
              onChange={handleChange}
              disabled={emailDisabled}
            />
          </div>
          <div className={`login__code-section ${codeSectionClass}`}>
            <p className="login__code-instructions">
              Please enter the security code sent via email.
            </p>
            <div className="login__form-group">
              <label className="login__label" htmlFor="code">
                Code:
              </label>
              <input
                className="login__input"
                name="code"
                typeof="text"
                id="code"
                value={code}
                onChange={handleChange}
                disabled={codeDisabled}
              />
            </div>
          </div>
          {/* end login__code-section */}
          <Button text="Submit" className="login__button" type="submit" />
        </form>
      </section>
    </main>
  );
}
