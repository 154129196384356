// NOTE:
// Local Storage booleans are stored as strings
// The Boolean() method will not Typecast the string to the correct boolan value
// and when you try to compare all results will return true
// we must compare booleans stings with "==="
export const editAndDeletePermissions = (isOwner) => {
  const isAdmin = window.localStorage.getItem("isAdmin");
  const isSuperUser = window.localStorage.getItem("isSuperUser");
  if (isAdmin === "true") {
    return true;
  }
  if (isSuperUser === "true") {
    return true;
  }
  if (isOwner === true) {
    return true;
  }
  return false;
};

export const userOwnsMessage = (ownerId) => {
  const authenticatedUser = Number(window.localStorage.getItem("uid"));
  if (authenticatedUser === ownerId) {
    return true;
  }
  return false;
};

export const superUserPermissions = () => {
  const isAdmin = window.localStorage.getItem("isAdmin");
  const isSuperUser = window.localStorage.getItem("isSuperUser");
  if (isAdmin === "true") {
    return true;
  }
  if (isSuperUser === "true") {
    return true;
  }
  return false;
};

export const adminPermissions = () => {
  const isAdmin = window.localStorage.getItem("isAdmin");
  if (isAdmin === "true") {
    return true;
  }
  return false;
};

export const canEditThisUser = (userRowisAdmin) => {
  // NOTE: this protects non-admins from editing Admins
  // if the logged in user is an admin
  // and the row they are viewing is an admin,
  // they can modify the user.
  const authUserIsAdmin = adminPermissions();
  if (authUserIsAdmin && userRowisAdmin) {
    return true;
  }

  if (!authUserIsAdmin && userRowisAdmin) {
    return false;
  }
  return true;
};
