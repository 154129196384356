export const showDropdownOptions = (event) => {
  // onClick: show custom dropdown options
  const target = event.target.dataset.target;
  document.querySelector(target).classList.toggle("d-none");
};

export const filterMessages = (
  filterByProduct,
  filterByBrand,
  filterByDeployment,
  filterByUsers
) => {
  // filter messages based upon the selections at the top of the dashboard.
  // filters will stack based upon user selections to become more specific.
  document.querySelectorAll("[data-type=message]").forEach((item) => {
    // remove all hidden messages
    item.classList.remove("d-none");
    // apply filters where needed
    if (filterByProduct) {
      if (item.dataset.filterProduct !== filterByProduct) {
        item.classList.add("d-none");
      }
    }
    if (filterByBrand) {
      if (item.dataset.filterBrand !== filterByBrand) {
        item.classList.add("d-none");
      }
    }
    if (filterByDeployment) {
      if (item.dataset.filterDeployment !== filterByDeployment) {
        item.classList.add("d-none");
      }
    }

    if (filterByUsers) {
      if (item.dataset.filterOwner !== filterByUsers) {
        item.classList.add("d-none");
      }
    }
  });
};
