export const toggleSortSelections = (event, type) => {
  if (type === "Priority") {
    // clear DAte
    document.querySelector(
      "#sort-type-Date option[value=None]"
    ).selected = true;
  }

  if (type === "Date") {
    // clear priority
    document.querySelector(
      "#sort-type-Priority option[value=None]"
    ).selected = true;
  }

  if (type === "Direction") {
    const direction = event.target.value;
    if (direction === "None") {
      document
        .querySelector(".dashboard__messages")
        .classList.remove("dashboard__messages--ascending");
      document
        .querySelector(".dashboard__messages")
        .classList.remove("dashboard__messages--descending");
    }
    if (direction === "descending") {
      document
        .querySelector(".dashboard__messages")
        .classList.add("dashboard__messages--descending");
      document
        .querySelector(".dashboard__messages")
        .classList.remove("dashboard__messages--ascending");
    }
    if (direction === "ascending") {
      document
        .querySelector(".dashboard__messages")
        .classList.add("dashboard__messages--ascending");
      document
        .querySelector(".dashboard__messages")
        .classList.remove("dashboard__messages--descending");
    }
  }
};
