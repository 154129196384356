import { Link } from "react-router-dom";
import { Heading, Label } from "../UILib";
import ampPermission from "./amp_permission.xlsx";

export default function Permission() {
  const handleClick = (event) => {
    let deploymentOptions = document.querySelectorAll("[data-type=permission]");
    deploymentOptions.forEach((element) => {
      element.checked = false;
    });
    event.target.checked = true;
  };
  return (
    <section className="permission">
      <div className="permission__content">
        <Heading type="h3" align="left" className="permission__h3">
          Select Permission Option:<sup>*</sup>
        </Heading>
        <div className="permission__input-group">
          <input
            id="ipad-only-view"
            name="ipad-only-view"
            type="radio"
            data-newmessageradio="true"
            data-type="permission"
            onClick={handleClick}
          />
          <Label type="l1" align="left" htmlFor="ipad-only-view">
            iPad only (view only)
          </Label>
        </div>
        <div className="permission__input-group">
          <input
            id="all-view"
            name="all-view"
            type="radio"
            data-newmessageradio="true"
            data-type="permission"
            onClick={handleClick}
          />
          <Label type="l1" align="left" htmlFor="all-view">
            ALL (can view, print, and email)
          </Label>
        </div>
        <div className="permission__input-group">
          <Link
            to={ampPermission}
            target="_blank"
            className="button button__stripped permission__button"
          >
            Click to view permissions file
          </Link>
        </div>
      </div>
    </section>
  );
}
