import { func, string, array } from "prop-types";
import { toggleSortSelections } from "./events";

export default function Sort({
  type,
  data,
  setMessageSortType,
  setMessageSortOption,
  setMessageSortDirection,
}) {
  const handleSort = (event) => {
    // prevents more than one sort type from being active at once
    toggleSortSelections(event, type);

    if (type === "Direction") {
      setMessageSortDirection(event.target.value);
      return;
    }
    setMessageSortType(type);
    setMessageSortOption(event.target.value);
  };

  return (
    <div className="sort">
      <div className="sort__type">
        <strong className="sort__type-strong">{type}</strong>
      </div>
      <select id={`sort-type-${type}`} onChange={handleSort}>
        <option value="None" data-priority="None" defaultValue>
          None
        </option>
        {data.map((item) => {
          return (
            <option
              value={type === "Priority" ? item.value : item.column_name}
              key={`${item.type}-${item.name}`}
            >
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

Sort.propTypes = {
  type: string,
  data: array,
  setMessageSortType: func,
  setMessageSortOption: func,
  setMessageSortDirection: func,
};
