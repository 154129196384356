import React, {useState} from "react";
import {array, bool, func, string} from "prop-types";
// https://www.npmjs.com/package/html-react-parser
// using this parser because there are some Products that come with <sup>&reg;</sup>
//static input field type will be excluded from message ( e.g. button text, label )
import parse from "html-react-parser";
import {Label} from "../UILib";

export const MessageTextInputField = ({
                                          id,
                                          labelText,
                                          maxLength,
                                          type,
                                          djfield, // django database fields
                                          fieldRequired, // bool
                                          handlePreviewChangeEvent,
                                          handleErrorEvent,
                                          disabled
                                      }) => {
    const [inputValue, setInputValue] = useState("");
    const [state, setState] = useState("");

    const handleChange = (event) => {
        console.log(maxLength)
        if (maxLength > 0) {
            if (event.target.value.length == maxLength) {
                event.target.labelText = "error";
                handleErrorEvent("error");
                event.target.classList.add("input--error");

            } else {
                setState(event.target.value);
                setInputValue({InputValue: event.target.value});
                if (event.target.classList.contains('input--error')) {
                    event.target.classList.remove('input--error');
                }
            }
        } else {
            setState(event.target.value);
            setInputValue({InputValue: event.target.value});
        }

    };

    return (
        <div className="msg-options">
            <Label type="l3" htmlFor={id} className="msg-options__label">
                {labelText}:{fieldRequired}
            </Label>
            <input
                id={id}
                name={id}
                maxLength={maxLength}
                className="msg-options__select"
                value={state.InputValue}
                data-type={type !== "static" ? "message-options" : 'static'}
                data-djangofield={djfield}
                data-newmessage={true}
                onChange={handleChange}
                onKeyUp={type !== "static" ? handlePreviewChangeEvent : null}
                required={fieldRequired === "*" && true}
                disabled={disabled}
            />
        </div>
    );
};

MessageTextInputField.propTypes = {
    labelText: string,
    type: string,
    djfield: string,
    data: array,
    handlePreviewChangeEvent: func,
    handleErrorEvent: func,
};

export const MessageDateInputField = ({
                                          id,
                                          labelText,
                                          type,
                                          djfield, // django database fields
                                          fieldRequired, // bool
                                          handlePreviewChangeEvent,
                                          disabled,
                                      }) => {
    const [inputValue, setInputValue] = useState("");
    const [state, setState] = useState("");
    const handleChange = (event) => {
        setState(event.target.value);
        setInputValue(event.target.value);
    };

    return (
        <div className="msg-options">
            <Label type="l3" htmlFor={id} className="msg-options__label">
                {labelText}:{fieldRequired}
            </Label>
            <input
                id={id}
                name={id}
                className="msg-options__select"
                value={state.InputValue}
                data-type="message-options"
                data-djangofield={djfield}
                data-newmessage={true}
                type="date"
                onChange={handleChange}
                onKeyUp={handlePreviewChangeEvent}
                onSelect={handlePreviewChangeEvent}
                required={fieldRequired === "*" && true}
                disabled={disabled}
            />
        </div>
    );
};

MessageDateInputField.propTypes = {
    type: string,
    djfield: string,
    data: array,
    handlePreviewChangeEvent: func,
};

export function ProductOptionField({
                                       id,
                                       labelText,
                                       data,
                                       djfield, // django database fields
                                       fieldRequired, // bool
                                       setSelectedProduct, // sets the active product
                                       setProductSelected, // Bool: toggles visibility of other form options
                                   }) {
    // Must strip the HTML from <option> text
    // and then feed back into HTML parser
    // because <option> would not accept the
    // html parsers return data
    const stringStripHTML = (text) => {
        let regex = /(<([^>]+)>)/gi;
        return text.replace(regex, "");
    };

    const handleChange = (event) => {
        if (event.target.value === "") {
            setSelectedProduct("");
            setProductSelected(false);
            return;
        }
        setSelectedProduct(event.target.value);
        setProductSelected(true);
    };

    return (
        <div className="msg-options">
            <Label type="l3" htmlFor={id} className="msg-options__label">
                {labelText}:{fieldRequired}
            </Label>
            <select
                id={id}
                name={id}
                className="msg-options__select"
                required={fieldRequired === "*" && true}
                onChange={handleChange}
            >
                <option className="msg-options__option" value="" defaultValue>
                    --select--
                </option>
                {data.map((item) => {
                    return (
                        <option
                            className="msg-options__option"
                            key={`${item.id}-${item.type}`}
                            value={item.product_name}
                            data-djangofield={djfield}
                            data-newmessage={true}
                        >
                            {
                                // <option> will not allow HTML as text
                                // strip out the HTML
                                // parse as HTML since the &reg; will still work
                                // once the <sup> tag is gone
                                parse(stringStripHTML(item.product_name))
                            }
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

ProductOptionField.propTypes = {
    labelText: string,
    djfield: string,
    data: array,
    setSelectedProduct: func,
    setProductSelected: func,
};

export function MessageSelectOptionField({
                                             id,
                                             labelText,
                                             data,
                                             djfield, // django database fields
                                             handlePreviewChangeEvent, // event hanlder for <Preview/>
                                             fieldRequired, // bool
                                             selectedProduct, // the product that was selected
                                             disabled,
                                         }) {
    // Must strip the HTML from <option> text
    // and then feed back into HTML parser
    // because <option> would not accept the
    // html parsers return data
    const stringStripHTML = (text) => {
        let regex = /(<([^>]+)>)/gi;
        return text.replace(regex, "");
    };

    console.log(`select disablec ${disabled}`)

    return (
        <div className="msg-options">
            <Label type="l3" htmlFor={id} className="msg-options__label">
                {labelText}:{fieldRequired}
            </Label>
            <select
                id={id}
                name={id}
                data-product = {selectedProduct}
                className="msg-options__select"
                onChange={handlePreviewChangeEvent}
                required={fieldRequired === "*" && true && !disabled}
                disabled = {disabled}
            >
                <option className="msg-options__option" value="" defaultValue>
                    --select--
                </option>
                {data.map((item) => {
                    // filter out options that don't apply
                    // to this brand
                    if (item.product_name !== selectedProduct) {
                        return;
                    }
                    return (
                        <option
                            className="msg-options__option **"
                            key={`${item.id}-${item.type}`}
                            value={item.value}
                            data-type="message-options"
                            data-djangofield={djfield}
                            data-newmessage={true}
                            data-product = {selectedProduct}
                        >
                            {
                                // <option> will not allow HTML as text
                                // strip out the HTML
                                // parse as HTML since the &reg; will still work
                                // once the <sup> tag is gone
                                parse(stringStripHTML(item.value))
                            }
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

MessageSelectOptionField.propTypes = {
    labelText: string,
    djfield: string,
    data: array,
    constructingPreview: bool,
    handlePreviewChangeEvent: func,
    selectedProduct: string,
};

export function MessageAutoSelectedField({id, labelText, type, text}) {
    return (
        <div className="msg-options msg-options--disabled">
            <Label type="l3" htmlFor={id} className="msg-options__label">
                {labelText}:
            </Label>
            <input
                id={id}
                name="autotext"
                className="msg-options__select"
                value={text}
                data-type="message-options"
                data-autotext={true}
                data-newmessage={true}
                type={type}
                disabled
            />
        </div>
    );
}
