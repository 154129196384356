import { lazy, Suspense, useState, useEffect } from "react";
import LabeledLoader from "../../../../components/LabeledLoader";
import { getData } from "../../../../utils/requests";
const User = lazy(() => import("../User"));

export default function Users({
  loadingUsers,
  setLoadingUsers,
  setModalClassName,
  setModalHeaderText,
  setModalBodyText,
  setModalBodyTextTop,
  setModalButtonOneText,
  setModalButtonOneType,
  setModalButtonOneClassName,
  setModalButtonOneOnClick,
  setModalButtonTwoText,
  setModalButtonTwoType,
  setModalButtonTwoClassName,
  setModalButtonTwoOnClick,
  setModalChildren,
  setUserInEdit,
  permissions,
  setPermissions,
  setNotification,
  setNotificationClass,
  resetNotifications,
}) {
  const [users, setUsers] = useState([]);
  const initialUserApiURL = `${process.env.REACT_APP_API_HOST}/api/user/`;
  const [userApiURL, setUserApiURL] = useState(initialUserApiURL);

  useEffect(() => {
    // At any pont, a user can trigger a loading users state
    // this will wipe out the current state and
    // re-pull the data from the API
    getData(userApiURL, setUserApiURL, setLoadingUsers, setUsers);
  }, [setLoadingUsers, userApiURL, initialUserApiURL]);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <section className="dashboard__messages">
        {loadingUsers ? (
          <LabeledLoader label="Loading Users..." />
        ) : (
          users.map((user) => {
            return (
              <User
                key={`userId-${user.id}-email-${user.email}`}
                user={user}
                setLoadingUsers={setLoadingUsers}
                setUsers={setUsers}
                setModalClassName={setModalClassName}
                setModalHeaderText={setModalHeaderText}
                setModalBodyText={setModalBodyText}
                setModalBodyTextTop={setModalBodyTextTop}
                setModalButtonOneText={setModalButtonOneText}
                setModalButtonOneType={setModalButtonOneType}
                setModalButtonOneClassName={setModalButtonOneClassName}
                setModalButtonOneOnClick={setModalButtonOneOnClick}
                setModalButtonTwoText={setModalButtonTwoText}
                setModalButtonTwoType={setModalButtonTwoType}
                setModalButtonTwoClassName={setModalButtonTwoClassName}
                setModalButtonTwoOnClick={setModalButtonTwoOnClick}
                setModalChildren={setModalChildren}
                setUserInEdit={setUserInEdit}
                permissions={permissions}
                setPermissions={setPermissions}
                setNotification={setNotification}
                setNotificationClass={setNotificationClass}
                resetNotifications={resetNotifications}
              />
            );
          })
        )}
      </section>
    </Suspense>
  );
}
