//====on confirm======
export const DeploymentStateUpdate = (deploymentType) => {
  let selections;
  let selectionsHtml = "";
  let counter = 0;
  let update = {};
  //Update State
  if (deploymentType === "State") {
    document.querySelector(".District-selections").innerHTML = "";
    document.querySelector(".cbsa-selections").innerHTML = "";
    document
      .querySelector(".District-selections")
      .removeAttribute("data-deployment-district-selected");

    selections = document.querySelector(".State-selections");
    document
      .querySelectorAll('[data-deployment-option-type="state"]')
      .forEach((item, key) => {
        if (item.checked) {
          counter += 1;
          selectionsHtml = `${selectionsHtml} ${item.dataset.deploymentOptionValue}<br/>`;
          update[item.dataset.deploymentOptionItemid] = {
            id: item.dataset.deploymentOptionItemid,
            state: item.dataset.deploymentOptionValue,
          };
        }
      });
    if (counter === 0) {
      selections.innerHTML = "";
    }
    if (counter > 0) {
      selections.innerHTML = `${selectionsHtml}`;
    }
    if (counter > 2) {
      selections.innerHTML = `Multiple states Selected`;
    }
    document
      .querySelector(".State-selections")
      .setAttribute("data-deployment-state-selected", JSON.stringify(update));
  }

  //update district
  if (deploymentType === "District") {
    document.querySelector(".State-selections").innerHTML = "";
    document.querySelector(".cbsa-selections").innerHTML = "";
    document
      .querySelector(".State-selections")
      .removeAttribute("data-deployment-state-selected");
    selections = document.querySelector(".District-selections");
    document
      .querySelectorAll('[data-deployment-option-type="district"]')
      .forEach((item, key) => {
        if (item.checked) {
          counter += 1;
          selectionsHtml = `${selectionsHtml} ${item.dataset.deploymentOptionValue}<br/>`;
          update[item.dataset.deploymentOptionItemid] = {
            id: item.dataset.deploymentOptionItemid,
            district: item.dataset.deploymentOptionValue,
          };
        }
      });
    if (counter === 0) {
      selections.innerHTML = "";
    }
    if (counter > 0) {
      selections.innerHTML = `${selectionsHtml}`;
    }
    if (counter > 2) {
      selections.innerHTML = `Multiple districts selected`;
    }
    document
      .querySelector(".District-selections")
      .setAttribute(
        "data-deployment-districts-selected",
        JSON.stringify(update)
      );
  }

  //update City/CBSA
  if (deploymentType === "City/CBSA") {
    document.querySelector(".State-selections").innerHTML = "";
    document.querySelector(".cbsa-selections").innerHTML = "";
    document
      .querySelector(".State-selections")
      .removeAttribute("data-deployment-state-selected");

    selections = document.querySelector(".cbsa-selections");

    document
      .querySelectorAll('[data-deployment-option-type="cbsa"]')
      .forEach((item, key) => {
        if (item.checked) {
          counter += 1;
          selectionsHtml = `${selectionsHtml} ${item.dataset.deploymentOptionValue}<br/>`;
          update[item.dataset.deploymentOptionItemid] = {
            id: item.dataset.deploymentOptionItemid,
            cbsa: item.dataset.deploymentOptionValue,
          };
        } else {
        }
      });
    if (counter === 0) {
      selections.innerHTML = "";
    }
    if (counter > 0) {
      selections.innerHTML = `${selectionsHtml}`;
    }
    if (counter > 2) {
      selections.innerHTML = `Multiple cities selectedd`;
    }

    document
      .querySelector(".cbsa-selections")
      .setAttribute("data-deployment-cbsa-selected", JSON.stringify(update));
  }
};

export const DeploymentReset = (deploymentType) => {
  //data-deployment-option-state : checkboxes
  if (
    deploymentType === "State" &&
    document.querySelector("[data-deployment-option-state]") !== null
  ) {
    let selectedState = JSON.parse(
      document
        .querySelector("[data-deployment-option-state]")
        .getAttribute("data-deployment-option-state")
    );

    if (
      // if any changes before save
      document
        .querySelector(".State-selections")
        .getAttribute("data-deployment-state-selected") !== null
    ) {
      selectedState = JSON.parse(
        document
          .querySelector(".State-selections")
          .getAttribute("data-deployment-state-selected")
      );
    }

    document
      .querySelectorAll("[data-type=deploymentOption]")
      .forEach((item) => {
        if (selectedState[item.dataset.deploymentOptionItemid] === undefined) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
  }

  // District-selections : changed
  if (
    deploymentType === "District" &&
    document.querySelector("[data-deployment-option-state]") !== null
  ) {
    let selectedDistrict = JSON.parse(
      document
        .querySelector("[data-deployment-option-state]")
        .getAttribute("data-deployment-option-state")
    );

    if (
      document
        .querySelector(".District-selections")
        .getAttribute("data-deployment-districts-selected") !== null
    ) {
      selectedDistrict = JSON.parse(
        document
          .querySelector(".District-selections")
          .getAttribute("data-deployment-districts-selected")
      );
    }

    document
      .querySelectorAll("[data-type=deploymentOption]")
      .forEach((item) => {
        if (
          selectedDistrict[item.dataset.deploymentOptionItemid] === undefined
        ) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
  }

  //if City/CBSA

  if (
    deploymentType === "City/CBSA" &&
    document.querySelector("[data-deployment-option-state]") !== null
  ) {
    let selectedCity = JSON.parse(
      document
        .querySelector("[data-deployment-option-state]")
        .getAttribute("data-deployment-option-state")
    );

    if (
      document
        .querySelector(".cbsa-selections")
        .getAttribute("data-deployment-cbsa-selected") !== null
    ) {
      selectedCity = JSON.parse(
        document
          .querySelector(".cbsa-selections")
          .getAttribute("data-deployment-cbsa-selected")
      );
    }

    document
      .querySelectorAll("[data-type=deploymentOption]")
      .forEach((item) => {
        if (selectedCity[item.dataset.deploymentOptionItemid] === undefined) {
          item.checked = false;
        } else {
          item.checked = true;
        }
      });
  }
};

//update deployoption selected State STring
export const updateStateSelectionString = () => {
  document.querySelector(".District-selections").innerHTML = "";
  document.querySelector(".cbsa-selections").innerHTML = "";
  document
    .querySelector(".District-selections")
    .removeAttribute("data-deployment-district-selected");

  let selections = document.querySelector(".State-selections");
  let selectionsHtml = "";
  let counter = 0;
  let update = {};
  document
    .querySelectorAll('[data-deployment-option-type="state"]')
    .forEach((item, key) => {
      if (item.checked) {
        counter += 1;
        selectionsHtml = `${selectionsHtml} ${item.dataset.deploymentOptionValue}<br/>`;
        update[item.dataset.deploymentOptionItemid] = {
          id: item.dataset.deploymentOptionItemid,
          state: item.dataset.deploymentOptionValue,
        };
      }
    });
  if (counter === 0) {
    selections.innerHTML = "";
  }
  if (counter > 0) {
    selections.innerHTML = `${selectionsHtml}`;
  }
  if (counter > 2) {
    selections.innerHTML = `Multiple states Selected`;
  }
};

//update deployoption selected District STring
export const updateDistricteSelectionString = () => {};

//update deployoption selected City STring
export const updateCitySelectionString = () => {};
